import React from "react";

export const Banner = ({ icon, text, actions }) => {
  return (
    <div className="bg-yellow-100 text-yellow-800 pl-4 pr-10 py-4 rounded relative">
      <div className="inline-block max-sm:mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 fill-yellow-500 inline mr-4"
          viewBox="0 0 128 128"
        >
          <path
            d="M56.463 14.337 6.9 106.644C4.1 111.861 8.173 118 14.437 118h99.126c6.264 0 10.338-6.139 7.537-11.356L71.537 14.337c-3.106-5.783-11.968-5.783-15.074 0z"
            data-original="#fad271"
          />
          <g fill="#fff">
            <path
              d="M64 31.726a5.418 5.418 0 0 0-5.5 5.45l1.017 44.289A4.422 4.422 0 0 0 64 85.726a4.422 4.422 0 0 0 4.482-4.261L69.5 37.176a5.418 5.418 0 0 0-5.5-5.45z"
              data-original="#fff"
            />
            <circle cx="64" cy="100.222" r="6" data-original="#fff" />
          </g>
        </svg>
      </div>
      <span className="block sm:inline text-sm mx-2 max-sm:ml-0 max-sm:mt-1">
        {text}
      </span>
    </div>
  );
};
