import React, { useEffect, useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../common/forms/resolvers";
import TextInputField from "../common/forms/fields/input.field";
import FormSection from "../common/forms/sections";
import ButtonInputField from "../common/forms/fields/input.button";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useUserMutation from "../../common/data/hooks/useUserMutation";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { usersState } from "../../common/state/state";
import { ACCOUNT_ROLE } from "../../common/data/constants";

const UserBasicDetailForm = ({
  userToUpdate,
  buttonText = null,
  setUserToUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState();
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const isOwnAccount = authUser?.uuid === userToUpdate?.uuid ? true : false;

  const setRegisterUsersState = useSetRecoilState(usersState);
  const registeredAccounts = useRecoilValue(usersState);

  const { data, loading, error, onUserCreateOrUpdateAccount } =
    useUserMutation();
  const {
    data: userStatusChanged,
    onUserChangeAccountStatus,
    loading: isChangingStatus,
    error: errorChangingStatus,
  } = useUserMutation();
  const {
    data: resetPassword,
    onUserChangeResetPassword,
    loading: isResetingPassword,
    error: errorResetingPassword,
  } = useUserMutation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: isEditing
      ? formFieldResolvers.updateUserForm
      : formFieldResolvers.registerForm,
  });

  useEffect(() => {
    if (userToUpdate) {
      setIsEditing(true);
      Object.entries(userToUpdate).map((user) => setValue(user[0], user[1]));
    }
  }, [userToUpdate, setIsEditing, setValue]);

  useEffect(() => {
    if (!isResetingPassword && resetPassword) {
      toast.success(resetPassword?.message);
      navigate("/auth/manage-accounts");
    }
    if (!isResetingPassword && errorResetingPassword) {
      toast.error(errorResetingPassword);
    }
  }, [resetPassword, isResetingPassword, errorResetingPassword]);

  useEffect(() => {
    if (!isChangingStatus && userStatusChanged?.success) {
      const accounts = registeredAccounts?.map((user) => {
        return user?.uuid === userToUpdate?.uuid ? getValues() : user;
      });
      setRegisterUsersState(accounts);
      // toast.success(userStatusChanged?.message);
      navigate("/auth/manage-accounts", {
        state: { isEdited: userStatusChanged?.success },
      });
    }

    if (!isChangingStatus && errorChangingStatus) {
      toast.error(errorChangingStatus);
    }
  }, [
    buttonText,
    navigate,
    getValues,
    userToUpdate,
    registeredAccounts,
    setRegisterUsersState,
    isStatusChanged,
    userStatusChanged,
    isChangingStatus,
    errorChangingStatus,
  ]);

  useEffect(() => {
    if (!loading && data) {
      // setUserToUpdate(data);
      toast.success(
        buttonText
          ? "Account successfully created"
          : "Account successfully updated"
      );
      navigate("/auth/manage-accounts");
    }

    if (!loading && error) {
      toast.error(error);
      if (!isEditing) {
        reset({ ...getValues() });
      }
    }
  }, [buttonText, reset, navigate, getValues, isEditing, data, loading, error]);

  const handleFormSubmit = (values) =>
    onUserCreateOrUpdateAccount(values, isEditing, userToUpdate);

  const handleStatusChange = (e) => {
    setValue("enabled", e.target.checked);
    setIsStatusChanged(e.target.checked);
    onUserChangeAccountStatus(userToUpdate, e.target.checked);
  };

  const handlePasswordChange = (e) => {
    // setValue("reset-password", e.target.checked);
    if (e.target.checked) {
      // setIsStatusChanged(e.target.checked);
      onUserChangeResetPassword(userToUpdate?.email);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col">
      <FormSection
        title="Basic account details"
        subTitle="Manage basic account information"
        show={true}
      >
        <TextInputField
          register={() => register("username")}
          type="text"
          disabled={isEditing && !isOwnAccount}
          label="Username"
          placeholder="Enter account username..."
          ariaPlaceholder="Enter account username"
          inputFieldError={errors.username}
        />
        <TextInputField
          register={() => register("email")}
          type="email"
          // restrict email update
          disabled={isEditing && !isOwnAccount}
          label="Email"
          placeholder="Enter a valid email address..."
          ariaPlaceholder="Enter a valid email address"
          inputFieldError={errors.email}
        />
        <TextInputField
          register={() => register("firstName")}
          type="text"
          disabled={isEditing && !isOwnAccount}
          label="Firstname"
          placeholder="Enter account firstname..."
          ariaPlaceholder="Enter account firstname"
          inputFieldError={errors.firstName}
        />
        <TextInputField
          register={() => register("lastName")}
          type="text"
          disabled={isEditing && !isOwnAccount}
          label="Lastname"
          placeholder="Enter account lastname..."
          ariaPlaceholder="Enter account lastname"
          inputFieldError={errors.lastName}
        />

        {isEditing && authUser?.roles?.includes(ACCOUNT_ROLE.ROLE_ADMIN) && (
          <div className="flex flex-row mt-1 space-x-2 justify-start">
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  id="enabled"
                  {...register("enabled")}
                  type="checkbox"
                  onChange={handleStatusChange}
                  disabled={isOwnAccount}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed"
                />
              </div>
              <div className="text-sm leading-6">
                <label
                  htmlFor="enabled"
                  className={[
                    "font-medium",
                    isOwnAccount ? "text-gray-600" : "text-gray-900",
                  ].join(" ")}
                >
                  Enabled
                </label>
              </div>
            </div>
          </div>
        )}

        {isEditing && authUser?.roles?.includes(ACCOUNT_ROLE.ROLE_ADMIN) && (
          <div className="flex flex-row mt-1 space-x-2 justify-start">
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  id="reset-password"
                  name="reset-password"
                  type="checkbox"
                  onChange={handlePasswordChange}
                  disabled={isResetingPassword}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed"
                />
              </div>
              <div className="text-sm leading-6">
                <label
                  htmlFor="reset-password"
                  className={[
                    "font-medium",
                    isOwnAccount ? "text-gray-600" : "text-gray-900",
                  ].join(" ")}
                >
                  Reset account password
                </label>
              </div>
            </div>
          </div>
        )}
      </FormSection>

      <FormSection
        className="py-2"
        title="Account password (Optional)"
        subTitle="Manage account password"
        show={!isEditing}
      >
        {/* {isOwnAccount ? (
          <TextInputField
            register={() => register("old_password")}
            type="password"
            label="Old Password"
            placeholder="Enter same password to confirm..."
            ariaPlaceholder="Enter same password to confirm"
            inputFieldError={errors?.old_password}
          />
        ) : null} */}

        <TextInputField
          register={() => register("password")}
          type="password"
          label="Password"
          placeholder="Enter account password..."
          ariaPlaceholder="Enter account password"
          inputFieldError={errors.password}
        />
        <TextInputField
          register={() => register("confirm_password")}
          type="password"
          label="Confirm Password"
          placeholder="Enter same password to confirm..."
          ariaPlaceholder="Enter same password to confirm"
          inputFieldError={{
            message: errors?.confirm_password?.message.split("_").join(" "),
          }}
        />
      </FormSection>

      <FormSection className="py-1.5" show={true}>
        <div className="flex flex-row">
          <ButtonInputField
            type="submit"
            value={buttonText ? buttonText : "Create new user account"}
          />
        </div>
      </FormSection>
    </form>
  );
};

export default UserBasicDetailForm;
