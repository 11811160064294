import SelectInputField from "../common/forms/fields/input.select";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../common/forms/resolvers";
import { useEffect, useState } from "react";
import { GIS_RESOURCE_TYPES } from "../../common/utils/gis.resource.types";
import useShapefileMutation from "../../common/data/hooks/useShapefileMutation";
import { ENERGY_RESOURCE_TYPES } from "../../common/utils/energy.resource.types";
import toast from "react-hot-toast";

const GisSelectionForm = ({
  regions,
  districts,
  activeTab,
  handleGisSelectionChange,
  handleSelectedDistrictForGis,
  selectedDistrictForEnergyResource,
  disbaleEnergyResource,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: formFieldResolvers.newShapefileForm,
  });
  const [selectedGisType, setSelectedGisType] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();

  const [selectedFile, setSelectedFile] = useState();
  const [areAllFileUploadParamsSelected, setAreAllFileUploadParamsSelected] =
    useState(false);
  const [energyResourceType, setEnergyResourceType] = useState();
  const {
    sendShapeFile,
    error: shapefileUploadError,
    data: shapefileUploadResponse,
    loading: shapefileUploadProgress,
  } = useShapefileMutation();

  const [gisOptions, setGisOptions] = useState([]);

  const handleGisSubmission = () => {
    switch (selectedGisType) {
      case "region boundary":
        const regionId = regions.find(
          (region) => region.name.toLowerCase() === selectedRegion.toLowerCase()
        )?._id;
        sendShapeFile(`/regions/${regionId}/upload-boundary`, selectedFile);
        break;
      case "district boundary":
        const districtId = districts.find(
          (district) =>
            district.properties.name.toLowerCase() ===
            selectedDistrict.toLowerCase()
        )?._id;
        sendShapeFile(`/districts/${districtId}/upload-boundary`, selectedFile);
        break;
      case "energy":
        const ditrictForGeojson = districts.find(
          (district) =>
            district.properties.name.toLowerCase() ===
            selectedDistrictForEnergyResource.toLowerCase()
        )?._id;
        const selectedResource = ENERGY_RESOURCE_TYPES.find(
          (resource) =>
            resource.name.toLowerCase() === energyResourceType.toLowerCase()
        );
        const path = `/districts/${ditrictForGeojson}/${selectedResource.path}`;
        sendShapeFile(path, selectedFile);
        break;
    }
  };

  const resetState = () => {
    setSelectedGisType("");
    setSelectedRegion();
    setSelectedDistrict();
    setSelectedFile();
    setAreAllFileUploadParamsSelected();
    setEnergyResourceType();
  };

  useEffect(() => {
    if (shapefileUploadError) {
      const errors = shapefileUploadError?.response?.data?.errors?.reduce(
        (err) => err
      );
      if (errors) {
        toast.error("Error: " + errors);
      } else {
        toast.error("Failed to upload boundary");
      }
      resetState();
    }

    if (shapefileUploadResponse) {
      toast.success("File Upload Success");
      resetState();
    }
  }, [shapefileUploadResponse, shapefileUploadError]);

  useEffect(() => {
    switch (activeTab) {
      case "region":
        if (regions) {
          setGisOptions(regions.map((region) => region.name).sort());
        }

        break;
      case "district":
        if (districts) {
          setGisOptions(
            districts.map((district) => district.properties.name).sort()
          );
        }
        break;
      case "energy":
        setGisOptions(
          ENERGY_RESOURCE_TYPES.map((resource) => resource.name).sort()
        );

        break;
    }
  }, [
    activeTab,
    districts,
    regions,
    selectedGisType,
    selectedDistrictForEnergyResource,
  ]);

  return (
    <form
      onSubmit={handleSubmit(handleGisSubmission)}
      className="flex flex-col w-full"
    >
      {activeTab === "energy" ? (
        <>
          <SelectInputField
            placeholder="Select district..."
            value={selectedDistrictForEnergyResource}
            options={districts
              .map((district) => district.properties.name)
              .sort()}
            handleChange={handleSelectedDistrictForGis}
            inputFieldError={errors.name}
          />
          {selectedDistrictForEnergyResource && (
            <SelectInputField
              placeholder={`Select ${activeTab}...`}
              value={selectedGisType}
              options={gisOptions}
              handleChange={handleGisSelectionChange}
              disabled={disbaleEnergyResource}
              inputFieldError={errors.name}
            />
          )}
        </>
      ) : (
        <SelectInputField
          placeholder={`Select ${activeTab}...`}
          value={selectedGisType}
          options={gisOptions}
          handleChange={handleGisSelectionChange}
          inputFieldError={errors.name}
        />
      )}
    </form>
  );
};

export default GisSelectionForm;
