import React, { useEffect, useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useForm } from "react-hook-form";
import Config from "../../common/data/api.service";
import formFieldResolvers from "../../common/forms/resolvers";
import FormSection from "../common/forms/sections";
import ButtonInputField from "../common/forms/fields/input.button";
import toast from "react-hot-toast";
import { Banner } from "../common/banner";
import UserAccountRoles, { ACCOUNT_ROLE_LIST } from "./user.roles";
import { ACCOUNT_ROLE } from "../../common/data/constants";
import { USER_ROLE } from "../../common/utils/constants";

const UserRolesForm = ({ userToUpdate, setUserToUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const authUser = useAuthUser();
  const isOwnAccount = authUser?.uuid === userToUpdate?.uuid ? true : false;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: formFieldResolvers.rolesForm,
  });

  useEffect(() => {
    if (userToUpdate) {
      setIsEditing(true);
      Object.entries(userToUpdate)?.map((user) =>
        setValue(user[0], user[0] === "roles" ? user[1][0] : user[1])
      );
    }
  }, [userToUpdate, setIsEditing, setValue]);

  useEffect(() => {}, [isEditing]);

  const handleFormSubmit = ({ roles }) => {
    Config.PROD_REMOTE_API.put(
      `users/${userToUpdate?.uuid}/roles?role=${roles}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authUser?.access_token}`,
        },
      }
    )
      .then((response) => response.data)
      .then((response) => {
        if (response?.success) {
          toast.success(response?.message);
        } else {
          toast.error(
            "Account role failed to update: " + JSON.stringify(response)
          );
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.errors[0]);
        console.log(error?.response?.data);
        throw new Error(error?.message);
      });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col">
      <FormSection
        className="py-3"
        title="Manage account roles"
        subTitle="This setting determines what this account can do in the system."
        show={true}
      >
        <Banner text="Only admin accounts can update roles and permissions. Also, these accounts cannot re-assign themselves other roles." />

        <UserAccountRoles
          errors={errors}
          // only show roles assigned to this user account
          roles={ACCOUNT_ROLE_LIST?.filter(
            (role) => role.name === userToUpdate?.roles[0]
          )}
          setValue={setValue}
          register={() => register("activeRole")}
          defaultRole={userToUpdate?.roles[0]}
        />

        {/* <hr className="text-slate-600 top-2" /> */}

        {
          // no account can update their own roles
          // allow only admins to update user roles
          !isOwnAccount &&
            authUser?.roles?.includes(ACCOUNT_ROLE.ROLE_ADMIN) && (
              <UserAccountRoles
                errors={errors}
                // only show roles not assigned to this user account
                roles={ACCOUNT_ROLE_LIST?.filter(
                  (role) => role.name !== userToUpdate?.roles[0]
                )}
                setValue={setValue}
                register={() => register("roles")}
              />
            )
        }
      </FormSection>

      <FormSection
        className="py-1.5"
        show={authUser?.roles.includes(USER_ROLE.ADMIN)}
      >
        <div className="flex flex-row">
          <ButtonInputField type="submit" value="Save changes" />
        </div>
      </FormSection>
    </form>
  );
};

export default UserRolesForm;
