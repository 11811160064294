import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import ManageMeProfile from "../components/users/me.profile";
import AuthRoleWrapper from "../components/users/roles/auth.role.wrapper";
import {
  cmsSelectedDirectoryCategoryStateKey,
  cmsSelectedFaqsCategoryStateKey,
  cmsSelectedFinancingCategoryStateKey,
  cmsSelectedHomeCategoryStateKey,
  cmsSelectedLibraryCategoryStateKey,
  cmsSelectedLicensingCategoryStateKey,
  cmsSelectedNewsCategoryStateKey,
} from "../common/state/state";
// import GeneralSettings from "../components/settings";
import CMSCreateCategory from "../components/common/category/cms.create.category";
import UserBasicDetailForm from "../components/users/user-details.form";
import UserProfilePage from "../components/users/user.profile";
import CMSCreateStakeholder from "../components/directory/cms.create.stakeholder";
import CMSStakeholderPage from "../components/directory/cms.stakeholder.page";
import CMSStakeholderDetails from "../components/directory/cms.directory.details";
import CMSCreateGis from "../components/gis/cms.create.gis";
import CMSGisPage from "../components/gis/cms.gis.page";

const PrivateContentWrapper = lazy(() =>
  import("../components/private.content.wrapper")
);
const ManageUserAccounts = lazy(() =>
  import("../components/users/manage.accounts")
);
const CMSCategoryPage = lazy(() =>
  import("../components/common/category/cms.category.page")
);
const CMSCategoryDetails = lazy(() =>
  import("../components/common/category/cms.category.details")
);

export const privateRoutes = [
  {
    path: "auth",
    element: (
      <PrivateContentWrapper>
        <Outlet />
      </PrivateContentWrapper>
    ),
    children: [
      {
        path: "home",
        element: (
          <CMSCategoryPage
            pathname={"home-category"}
            pageToolBarLandingTitle={"Manage Home"}
            pageToolBarLandingSubTitle={"Manage public home information"}
            pageToolBarLandingLabel={"Create new category"}
            pageToolBarSubDetailsTitle={`category details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedHomeCategoryStateKey}
            searchPlaceholder="home categories"
          >
            <Outlet />
          </CMSCategoryPage>
        ),
        children: [
          {
            path: ":categoryId/details",
            element: (
              <CMSCategoryDetails
                recoilCategoryStateKey={cmsSelectedHomeCategoryStateKey}
              />
            ),
          },
          {
            path: "new-home-category",
            element: (
              <CMSCreateCategory
                formDescription="Create a new home category"
                submitButtonLabel="Create new category"
              />
            ),
          },
          {
            path: "root-category",
            element: (
              <CMSCreateCategory
                formDescription="Create the root category"
                submitButtonLabel="Create root category"
              />
            ),
          },
        ],
      },
      // {
      //   path: "general/settings",
      //   element: <GeneralSettings />,
      //   children: [],
      // },
      {
        path: "manage-accounts",
        element: (
          // Allow only admins access to and management of other accounts
          <AuthRoleWrapper>
            <ManageUserAccounts>
              <Outlet />
            </ManageUserAccounts>
          </AuthRoleWrapper>
        ),
        children: [
          {
            path: ":accountId/edit",
            element: <UserProfilePage />,
          },
          {
            path: "new-account",
            element: <UserBasicDetailForm />,
          },
        ],
      },
      {
        path: "me",
        element: (
          <ManageMeProfile>
            <Outlet />
          </ManageMeProfile>
        ),
        children: [
          {
            path: ":accountId/details",
            element: <UserProfilePage />,
          },
        ],
      },
      {
        path: "faqs",
        element: (
          <CMSCategoryPage
            pathname={"faqs-question"}
            pageToolBarLandingTitle={"Manage Frequently Asked Questions"}
            pageToolBarLandingSubTitle={
              "Manage public frequently asked questions information"
            }
            pageToolBarLandingLabel={"Create new question"}
            pageToolBarSubDetailsTitle={`category details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedFaqsCategoryStateKey}
            searchPlaceholder="questions"
          >
            <Outlet />
          </CMSCategoryPage>
        ),
        children: [
          {
            path: ":questionId/details",
            element: (
              <CMSCategoryDetails
                recoilCategoryStateKey={cmsSelectedFaqsCategoryStateKey}
              />
            ),
          },
          {
            path: "new-faqs-question",
            element: (
              <CMSCreateCategory
                formDescription="Create a new question"
                submitButtonLabel="Create new question"
              />
            ),
          },
        ],
      },
      {
        path: "library",
        element: (
          <CMSCategoryPage
            pathname={"document"}
            pageToolBarLandingTitle={"Manage Library Documents"}
            pageToolBarLandingSubTitle={
              "Manage public documents and related information on minigrids development"
            }
            pageToolBarLandingLabel={"Create new document"}
            pageToolBarSubDetailsTitle={`document details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedLibraryCategoryStateKey}
            searchPlaceholder="library documents"
          >
            <Outlet />
          </CMSCategoryPage>
        ),
        children: [
          {
            path: ":documentId/details",
            element: (
              <CMSCategoryDetails
                recoilCategoryStateKey={cmsSelectedLibraryCategoryStateKey}
              />
            ),
          },
          {
            path: "new-document",
            element: (
              <CMSCreateCategory
                formDescription="Create a new library document"
                submitButtonLabel="Create new document"
              />
            ),
          },
        ],
      },
      {
        path: "directory",
        element: (
          <CMSStakeholderPage
            pathname={"stakeholder"}
            pageToolBarLandingTitle={"Manage Stakeholders"}
            pageToolBarLandingSubTitle={
              "Manage public directory or stakeholders' information"
            }
            pageToolBarLandingLabel={"Create new stakeholder"}
            pageToolBarSubDetailsTitle={`stakeholder details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedDirectoryCategoryStateKey}
            searchPlaceholder="stakeholders"
          >
            <Outlet />
          </CMSStakeholderPage>
        ),
        children: [
          {
            path: ":stakeholderId/details",
            element: (
              <CMSStakeholderDetails
                recoilCategoryStateKey={cmsSelectedDirectoryCategoryStateKey}
              />
            ),
          },
          {
            path: "new-stakeholder",
            element: <CMSCreateStakeholder />,
          },
        ],
      },
      {
        path: "gis",
        // element: <AuthGIS />,
        element: (
          <CMSGisPage
            pathname={"gis"}
            pageToolBarLandingTitle={"Manage GIS"}
            pageToolBarLandingSubTitle={
              "Manage GIS related to energy resources"
            }
            pageToolBarLandingLabel={"create new GIS"}
            pageToolBarSubDetailsTitle={`GIS details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedNewsCategoryStateKey}
            searchPlaceholder="search gis"
          >
            <Outlet />
          </CMSGisPage>
        ),
        children: [
          {
            path: "new-gis",
            element: (
              <CMSCreateGis
                formDescription="Create a new gis"
                submitButtonLabel="Create new gis"
              />
            ),
          },
        ],
      },
      {
        path: "news",
        element: (
          <CMSCategoryPage
            pathname={"article"}
            pageToolBarLandingTitle={"Manage News"}
            pageToolBarLandingSubTitle={
              "Manage public news and information related to energy resources"
            }
            pageToolBarLandingLabel={"Create new article"}
            pageToolBarSubDetailsTitle={`category details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedNewsCategoryStateKey}
            searchPlaceholder="news articles"
          >
            <Outlet />
          </CMSCategoryPage>
        ),
        children: [
          {
            path: ":articleId/details",
            element: (
              <CMSCategoryDetails
                recoilCategoryStateKey={cmsSelectedNewsCategoryStateKey}
              />
            ),
          },
          {
            path: "new-article",
            element: (
              <CMSCreateCategory
                formDescription="Create a new article"
                submitButtonLabel="Create new article"
              />
            ),
          },
        ],
      },
      {
        path: "licensing",
        element: (
          <CMSCategoryPage
            pathname={"licensing-step"}
            pageToolBarLandingTitle={"Manage Licensing"}
            pageToolBarLandingSubTitle={
              "Manage public information on minigrids licensing processes"
            }
            pageToolBarLandingLabel={"Create new step"}
            pageToolBarSubDetailsTitle={`category details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedLicensingCategoryStateKey}
            searchPlaceholder="licensing"
          >
            <Outlet />
          </CMSCategoryPage>
        ),
        children: [
          {
            path: ":stepId/details",
            element: (
              <CMSCategoryDetails
                recoilCategoryStateKey={cmsSelectedLicensingCategoryStateKey}
              />
            ),
          },
          {
            path: "new-licensing-step",
            element: (
              <CMSCreateCategory
                formDescription="Create a new licensing step"
                submitButtonLabel="Create new step"
              />
            ),
          },
        ],
      },
      {
        path: "financing",
        element: (
          <CMSCategoryPage
            pathname={"strategy"}
            pageToolBarLandingTitle={"Manage Financing"}
            pageToolBarLandingSubTitle={
              "Manage public information on minigrids financing strategies"
            }
            pageToolBarLandingLabel={"Create new strategy"}
            pageToolBarSubDetailsTitle={`category details`}
            pageToolBarNewCategorySubTitle={``}
            recoilCategoryStateKey={cmsSelectedFinancingCategoryStateKey}
            searchPlaceholder="strategies"
          >
            <Outlet />
          </CMSCategoryPage>
        ),
        children: [
          {
            path: ":stepId/details",
            element: (
              <CMSCategoryDetails
                recoilCategoryStateKey={cmsSelectedFinancingCategoryStateKey}
              />
            ),
          },
          {
            path: "new-strategy",
            element: (
              <CMSCreateCategory
                formDescription="Create a new financing strategy"
                submitButtonLabel="Create new strategy"
              />
            ),
          },
        ],
      },
    ],
  },
];
