import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import CategoryUploadFileForm from "../common/forms/upload-file.form";
import {
  PhotoIcon,
  PhoneArrowDownLeftIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { CategorySection } from "../common/page.section";
import StakeholderContactInforForm from "./stakeholder.contact";
import CategoryInforForm from "../common/forms/category-infor.form";
import { InforType } from "../../common/data/constants";

const CMSStakeholderDetails = ({ recoilCategoryStateKey }) => {
  const stakeholder = useRecoilValue(recoilCategoryStateKey);
  const setUpdatedCategory = useSetRecoilState(recoilCategoryStateKey);

  return (
    <div className="flex flex-row w-full h-full justify-between">
      <div className="w-full">
        <CategorySection
          icon={InformationCircleIcon}
          label="Details"
          description="Basic stakeholder details & information"
        >
          <CategoryInforForm
            category={stakeholder}
            setUpdatedCategory={setUpdatedCategory}
            approvable={false}
            type={InforType.STAKEHOLDER}
          />
        </CategorySection>

        <CategorySection
          icon={PhoneArrowDownLeftIcon}
          label="Contacts"
          description="Stakeholder's contact details"
        >
          <StakeholderContactInforForm
            category={stakeholder}
            setUpdatedCategory={setUpdatedCategory}
            headerText={
              <span>
                Contacts users might need to reach out to{" "}
                <span className="font-semibold">{stakeholder?.name}</span>.
              </span>
            }
          />
        </CategorySection>

        <CategorySection
          icon={PhotoIcon}
          label="Profile photo"
          description="Attach profile photo for the stakeholder"
        >
          <CategoryUploadFileForm
            category={stakeholder}
            setUpdatedCategory={setUpdatedCategory}
            headerText={
              <span>
                Official logo for{" "}
                <span className="font-semibold">{stakeholder?.name}</span>.
              </span>
            }
            uploadPath={`stakeholders/${stakeholder?._id}/files`}
            type={InforType.STAKEHOLDER}
          />
          <div className="mb-10"></div>
        </CategorySection>
      </div>
    </div>
  );
};

export default CMSStakeholderDetails;
