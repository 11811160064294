import React, { Fragment, useEffect, useState } from "react";
import Button from "../buttons";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import FileUploadManager from "../category/file.upload.manager";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import FilePreviewZone from "../category/file.preview";
import useFileMutation from "../../../common/data/hooks/useFileMutation";
import { algorithms } from "../../../common/data/algorithms";
import { InforType } from "../../../common/data/constants";

const CategoryUploadFileForm = ({
  type,
  category,
  headerText,
  uploadPath,
  setUpdatedCategory,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const authUser = useAuthUser();
  const {
    deleteFiles,
    data,
    loading: deleteLoading,
    error,
  } = useFileMutation();

  const toggleEdit = () => setIsEditing((current) => !current);

  useEffect(() => {
    setUploadedFiles(category?.documents);
  }, [category, setUploadedFiles]);

  const removeItem = (categoryId) => {
    const filterd = uploadedFiles.filter((f) => f._id !== categoryId);
    setUploadedFiles(filterd);
  };

  const onDelete = async (fileId) => {
    setDeletingId(fileId);
    deleteFiles(category?._id, fileId, type);
    removeItem(fileId);
  };

  const handleLogoDelete = () => {
    deleteFiles(category?._id, category?.image, type);
  };

  useEffect(() => {
    if (data && type === InforType.CATEGORY) {
      if (!error) {
        setUpdatedCategory(data);
        setUploadedFiles(data?.documents);
        toast.success("File successfully deleted");
      }
    }

    if (data && type === InforType.STAKEHOLDER) {
      if (data?.success) {
        const { image, ...props } = category;
        setUpdatedCategory(props);
        toast.success(data?.message);
      }
    }

    if (error) {
      console.log(error);
      toast.error(error?.response?.data?.errors[0]);
      setDeletingId(null);
    }
  }, [
    category,
    type,
    data,
    deleteLoading,
    error,
    setUpdatedCategory,
    setUploadedFiles,
    setDeletingId,
  ]);

  return (
    <div className="flex flex-col">
      <div className="font-medium flex items-center justify-between">
        <div className="text-gray-400 text-xs text-muted-foreground mt-2">
          {headerText}
        </div>
        {authUser?.username === category?.createdBy ? (
          <span>
            <Button onClick={() => handleLogoDelete()} variant="ghost">
              {!isEditing &&
                category?.image &&
                type === InforType.STAKEHOLDER && (
                  <>
                    <XMarkIcon className="h-4 w-4 mr-2" />
                    Delete logo
                  </>
                )}
            </Button>
            <Button onClick={toggleEdit} variant="ghost">
              {isEditing && (
                <>
                  <XMarkIcon className="h-4 w-4 mr-2" />
                  Cancel
                </>
              )}
              {!isEditing && (
                <>
                  <PlusIcon className="h-4 w-4 mr-2" />
                  {!category?.image && type === InforType.STAKEHOLDER
                    ? "Add official logo"
                    : type === InforType.STAKEHOLDER && "Change official logo"}
                  {!category?.documents && type === InforType.CATEGORY
                    ? "Add documents"
                    : type === InforType.CATEGORY && "Manage documents"}
                </>
              )}
            </Button>
          </span>
        ) : null}
      </div>
      {!isEditing && (
        <Fragment>
          {!category?.documents && type === InforType.CATEGORY ? (
            <p className="text-sm mt-2 text-slate-500 italic">
              No files added yet
            </p>
          ) : (
            category?.documents && (
              <FilePreviewZone
                uploadedFiles={category?.documents}
                removeItem={onDelete}
                deletingId={deletingId}
                loading={deleteLoading}
                deletable={authUser?.username === category?.createdBy}
              />
            )
          )}

          {!category?.image && type === InforType.STAKEHOLDER ? (
            <p className="text-sm mt-2 text-slate-500 italic">
              No files added yet
            </p>
          ) : (
            category?.image && (
              <div className="flex gap-5 items-center justify-start">
                <img
                  src={category?.image}
                  alt="avatar"
                  className="image-input-wrapper w-20 h-20 rounded-full cursor-pointer opacity-75-hover"
                />
                <span>
                  {algorithms?.firstLettersToUpperCase(
                    `${category?.name}'s official logo.`
                  )}
                </span>
              </div>
            )
          )}
        </Fragment>
      )}
      {isEditing && (
        <div>
          <FileUploadManager
            path={uploadPath}
            onNewFileDropped={(file) => {
              console.log("new file added by user", file);
            }}
            onClientStartedUpload={(file) => {
              console.log("new file added by user", file);
            }}
            onClientFinishedUpload={(response) => {
              setUpdatedCategory(response);
              setIsEditing(false);
            }}
            onUploadError={(error) => {
              console.log(error);
            }}
            label={
              <div className="text-gray-400 text-xs text-muted-foreground mt-2">
                Add anything users might need to view in this category.
              </div>
            }
          >
            <p className="text-xs text-gray-500 dark:text-gray-400">
              PDF, .DOCX
            </p>
          </FileUploadManager>
        </div>
      )}
    </div>
  );
};

export default CategoryUploadFileForm;
