import React from "react";

const Checkbox = React.forwardRef(
  ({ className, label, isVisible, ...props }, ref) => {
    return (
      <div className="inline-flex items-center">
        <label
          className={[
            `relative flex items-center ${
              className ? "" : "px-2"
            } rounded-full cursor-pointer`,
            className,
          ].join(" ")}
          htmlFor="checkbox"
        >
          <input
            type="checkbox"
            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-green-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-green-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
            id="checkbox"
            ref={ref}
            {...props}
          />
        </label>
        <span className="ml-2 font-semibold text-gray-600">{label}</span>
      </div>
    );
  }
);

export default Checkbox;
