import React from "react";

const PageToolBar = ({ title, subTitle, children }) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <div className="text-2xl pl-2 font-semibold border-l-4 border-l-green-700">
          {title}
        </div>
        <span className="text-sm text-gray-500">{subTitle}</span>
      </div>
      {children}
    </div>
  );
};

export default PageToolBar;
