import { useState } from "react";
import { GIS_TABS } from "../../common/utils/gis.tabs";

const useActiveGisTabs = () => {
  const [tabs, setTabs] = useState();
  const activeDefaultTab = () => {
    return GIS_TABS[0];
  };
  return { tabs, setTabs, activeDefaultTab };
};

export default useActiveGisTabs;
