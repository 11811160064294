import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Button } from "../common/forms/fields/input.button";
import PageToolBar from "../common/toolbars/page.toolbar";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
// import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const ManageMeProfile = ({ children }) => {
  const navigate = useNavigate();
  const matchDetailsScreen = useMatch("/auth/me/:accountId/details");
  const matchEditScreen = useMatch("/auth/me/:accountId/edit");
  // const authUser = useAuthUser();
  // console.log(authUser);

  return (
    <div className="flex flex-col justify-center">
      {Boolean(matchDetailsScreen) && (
        <PageToolBar
          title={
            <Button
              handleOnClick={() => navigate(-1)}
              label="Go Back"
              className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
            >
              <ArrowLongLeftIcon className="w-6 h-6 pr-1" />
            </Button>
          }
          subTitle=""
        ></PageToolBar>
      )}
      {Boolean(matchEditScreen) && (
        <PageToolBar
          title={
            <Button
              handleOnClick={() => navigate(-1)}
              label="Go Back"
              className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
            >
              <ArrowLongLeftIcon className="w-6 h-6 pr-1" />
            </Button>
          }
          subTitle=""
        ></PageToolBar>
      )}
      <div className="flex flex-col mt-2 w-full h-full rounded-md bg-white shadow-sm">
        <div className="p-3">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ManageMeProfile;
