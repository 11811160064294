import { useCallback, useEffect, useState } from "react";
import Config from "../../../common/data/api.service";
import { algorithms } from "../../../common/data/algorithms";

const useStakeholderQuery = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const refetch = useCallback(async (path) => {
    setLoading(true);
    if (path !== undefined && path !== null) {
      const data = await Config.PROD_REMOTE_API.get(path)
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          return data.map((stakeholder) => {
            return {
              ...stakeholder,
              createdDate: algorithms.formatDate.toLocaleDateString(
                stakeholder.createdDate
              ),
              lastModifiedDate: algorithms.formatDate.toLocaleDateString(
                stakeholder.lastModifiedDate
              ),
            };
          });
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setError(err);
        });
      setData(data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    refetch(url);
  }, [url, refetch, setLoading, setError, setData]);

  return { loading, data, error, refetch };
};

export default useStakeholderQuery;
