import {
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { LoaderIcon } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { algorithms } from "../../../common/data/algorithms";

const FilePreviewZone = ({
  uploadedFiles,
  removeItem,
  deletingId,
  deletable,
  loading,
}) => {
  return (
    <div>
      {uploadedFiles?.length > 0 && (
        <ul className="w-full mt-2 mb-0">
          {uploadedFiles?.map((file, index) => (
            <li
              className="bg-green-50 px-5 py-3 w-full mt-2 rounded-lg"
              key={index}
            >
              <div className="flex justify-between items-center w-full">
                {file?.fileType?.startsWith("image/") ? (
                  <div className="flex gap-5 items-center">
                    <img
                      // src={URL.createObjectURL(file)}
                      src={file?.fileLink}
                      alt="avatar"
                      className="image-input-wrapper w-12 h-12 rounded-full cursor-pointer opacity-75-hover"
                    />
                    <span className="hover:underline">
                      {algorithms?.firstLetterOnlyToUpperCase(file?.fileName)}
                    </span>
                  </div>
                ) : (
                  <div className="flex gap-5 items-center">
                    <CloudArrowDownIcon className="h-6 w-6 mr-2 flex-shrink-0" />
                    <span>
                      <NavLink
                        to={file?.fileLink}
                        role="_blank"
                        about={file?.fileName}
                        className="hover:underline"
                      >
                        {algorithms?.firstLetterOnlyToUpperCase(file?.fileName)}
                      </NavLink>
                    </span>
                  </div>
                )}
                {deletingId === file._id && loading && (
                  <LoaderIcon className="h-6 w-6 animate-spin" />
                )}
                {deletable && deletingId !== file._id && (
                  <span
                    onClick={() => removeItem(file._id)}
                    className="cursor-pointer hover:opacity-75 transition"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="1"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilePreviewZone;
