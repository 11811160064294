import React, { useEffect } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import useMatchedPage from "../common/category/useMatchingPage";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useStakeholderQuery from "../../common/data/hooks/useStakeholderQuery";
import CategoryPageFactory, {
  PageContentSection,
} from "../common/category/category.page.factory";
import PageToolBar from "../common/toolbars/page.toolbar";
import { Button } from "../common/forms/fields/input.button";
import { ArrowLongLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { PAGE_MATCHED, USER_ROLE } from "../../common/utils/constants";
import useStakeholderMutation from "../../common/data/hooks/useStakeholderMutation";
import toast from "react-hot-toast";
import CMSStakeholderList from "./cms.directory.list";

const CMSStakeholderPage = ({
  pathname,
  pageToolBarLandingTitle,
  pageToolBarLandingSubTitle,
  pageToolBarLandingLabel,
  pageToolBarDetailsTitle = null,
  pageToolBarDetailsSubTitle,
  pageToolBarNewCategoryTitle = null,
  pageToolBarNewCategorySubTitle,
  recoilCategoryStateKey,
  searchPlaceholder,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const categoryPage = paths[2]; // get third pathname for the active category

  const matchLanding = useMatch(`${location.pathname}`);
  const [matchedPage] = useMatchedPage(paths[paths.length - 1], pathname);

  const authUser = useAuthUser();
  // const { tabs, setTabs, activeDefaultTab } = useActiveDefaultTab();
  // const [selected, setSelected] = useState(activeDefaultTab());
  // const [selectedCategoryToView, setSelectedCategoryToView] = useState({});

  // const [isOverlayOpen, setOverlayOpen] = useState(false);
  // const toggleOverlay = () => setOverlayOpen((open) => !open);

  const {
    data: stakeholders,
    loading: isFetching,
    // refetch,
  } = useStakeholderQuery(`stakeholders`);
  const { data, loading, error, deleteStakeholder } = useStakeholderMutation();

  const handleDelete = (id) => deleteStakeholder(id);

  useEffect(() => {
    if (data) {
      toast.success("Stakeholder successfully deleted");
      navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}`, {
        state: data,
      });
    }

    if (error) {
      toast.error(error?.response?.data?.errors[0]);
      console.log(error?.response?.data);
    }
  }, [data, loading, error, navigate, pathname]);

  return (
    <div className="flex flex-col justify-center">
      <CategoryPageFactory
        pageView={matchedPage}
        pageToolBarLanding={() => (
          <PageToolBar
            title={pageToolBarLandingTitle}
            subTitle={pageToolBarLandingSubTitle}
          >
            {authUser?.roles.includes(USER_ROLE.AUTHOR) && (
              <Button
                handleOnClick={() => navigate(`new-${pathname}`)}
                label={pageToolBarLandingLabel}
                className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
              >
                <PlusIcon className="w-6 h-6 pr-1" />
              </Button>
            )}
          </PageToolBar>
        )}
        pageToolBarDetails={() => (
          <PageToolBar
            title={
              <Button
                handleOnClick={() => navigate(-1)}
                label="Go Back"
                className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
              >
                <ArrowLongLeftIcon className="w-6 h-6 pr-1" />
              </Button>
            }
            subTitle={pageToolBarDetailsSubTitle}
          ></PageToolBar>
        )}
        pageToolBarNewCategory={() => (
          <PageToolBar
            title={
              <Button
                handleOnClick={() => navigate(-1)}
                label="Go Back"
                className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
              >
                <ArrowLongLeftIcon className="w-6 h-6 pr-1" />
              </Button>
            }
            subTitle={pageToolBarNewCategorySubTitle}
          />
        )}
      >
        <PageContentSection>
          {Boolean(matchLanding) &&
          matchedPage === PAGE_MATCHED.LANDING_SCREEN ? (
            <div className="flex flex-row w-full h-full justify-between">
              <div
                className={[
                  `p-2 h-full w-full rounded-md bg-white shadow-sm`,
                  // isOverlayOpen ? `w-[80%]` : `w-[98%]`,
                ].join(" ")}
              >
                <CMSStakeholderList
                  loading={loading || isFetching}
                  authUser={authUser}
                  // setSelectedCategoryToView={setSelectedCategoryToView}
                  stakeholders={stakeholders}
                  categoryPage={categoryPage}
                  recoilCategoryStateKey={recoilCategoryStateKey}
                  searchPlaceholder={searchPlaceholder}
                  onDelete={handleDelete}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col w-full h-full">{children}</div>
          )}
        </PageContentSection>
      </CategoryPageFactory>
    </div>
  );
};

export default CMSStakeholderPage;
