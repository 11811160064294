import { Fragment } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { Navigate } from "react-router-dom";
import { ACCOUNT_ROLE } from "../../../common/data/constants";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

const AuthRoleWrapper = ({ children }) => {
  const authUser = useAuthUser();
  const isAuth = useIsAuthenticated();

  if (isAuth && !authUser?.roles?.includes(ACCOUNT_ROLE.ROLE_ADMIN)) {
    return <Navigate to={`/auth/me/${authUser?.uuid}/details`} replace />;
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthRoleWrapper;
