import { ACCOUNT_ROLE } from "../../common/data/constants";
import RadioInputField from "../common/forms/fields/radio.field";

export const ACCOUNT_ROLE_LIST = [
  {
    id: 0,
    name: ACCOUNT_ROLE.ROLE_AUTHOR,
    description:
      "Generates all content that appear on the public portal. This role allows an account to manage categories created by them, but cannot delete what was created by another account. Also what has been sent for review cannot be accessed by the owner of the category.",
    permissions: [
      {
        name: "Create, update and delete categories not yet marked for review that they manage.",
      },
    ],
  },
  {
    id: 1,
    name: ACCOUNT_ROLE.ROLE_EDITOR,
    description:
      "Reviews author's created content. This role allows an account to review content, approve or disapprove",
    permissions: [],
  },
  {
    id: 2,
    name: ACCOUNT_ROLE.ROLE_PUBLISHER,
    description:
      "Manages reviewed and published publicly accessible content. Also allows the account to update the root/level 1 categories.",
    permissions: [],
  },
  {
    id: 3,
    name: ACCOUNT_ROLE.ROLE_ADMIN,
    description:
      "Manages all user accounts in the system. This role allows an account to assign new roles to accounts besides performing CRUDE (CREATE, READ, UPDATE, DELETE) functions. Also allows the account to update the root/level 1 categories.",
    permissions: [],
  },
];

const RolePermision = ({ permission }) => {
  return (
    <div className="flex flex-row">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="pt-0.5">{permission?.name}</div>
    </div>
  );
};

const UserAccountRoles = ({
  errors,
  register,
  setValue,
  defaultRole,
  roles,
}) => {
  return roles?.map((role) => {
    return (
      <RadioInputField
        key={role.name}
        register={register}
        label={role.name}
        type="radio"
        setValue={setValue}
        checked={defaultRole && defaultRole === role?.name ? true : undefined}
        inputFieldError={errors.roles}
      >
        <div className="flex flex-col pt-0 text-sm text-gray-500">
          {role.description}
        </div>
        <div className="flex flex-col text-xs pl-3 text-gray-400">
          {role?.permissions?.map((permission, index) => {
            return (
              <RolePermision
                key={index}
                permission={permission}
                // register={() => register("permission")}
              />
            );
          })}
        </div>
      </RadioInputField>
    );
  });
};

export default UserAccountRoles;
