import { useCallback, useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Config from "../api.service";

const useShapefileMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const authUser = useAuthUser();

  const sendShapeFile = useCallback(
    (urlPath, file) => {
      if (urlPath !== undefined && urlPath !== null) {
        setLoading(true);
        const categoryDocuments = new FormData();
        categoryDocuments.append("file", file);
        Config.PROD_REMOTE_API.post(urlPath, categoryDocuments, {
          headers: { Authorization: `Bearer ${authUser?.access_token}` },
        })
          .then((res) => res.data)
          .then((response) => {
            setData(response);
            setLoading(false);
            setError();
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setData();
            setError(error);
          });
      }
    },
    [authUser?.access_token]
  );

  const deleteGisData = useCallback(
    (path) => {
      if (path) {
        setLoading(true);
        Config.PROD_REMOTE_API.delete(path, {
          headers: { Authorization: `Bearer ${authUser?.access_token}` },
        })
          .then((res) => res.data)
          .then((response) => {
            setData(response);
            setLoading(false);
            setError();
          })
          .catch((error) => {
            setLoading(false);
            setError(error);
            setData();
          });
      }
    },
    [authUser?.access_token]
  );
  return { loading, data, error, sendShapeFile, deleteGisData };
};

export default useShapefileMutation;
