export const GIS_TABS = [
  {
    order: 0,
    name: "region",
    label: "Region boundary",
    active: true,
  },
  {
    order: 1,
    name: "district",
    label: "District boundary",
    active: false,
  },
  {
    order: 2,
    name: "energy",
    label: "Energy resource",
    active: false,
  },
];
