import { useCallback, useState } from "react";
import Config from "../../../common/data/api.service";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { algorithms } from "../algorithms";

const useCategoryMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const authUser = useAuthUser();

  const createCategory = useCallback(
    (urlPath, category) => {
      if (urlPath !== undefined && urlPath !== null) {
        setLoading(true);
        Config.PROD_REMOTE_API.post(
          urlPath,
          { ...category },
          {
            headers: { Authorization: `Bearer ${authUser?.access_token}` },
          }
        )
          .then((res) => res.data)
          .then((response) => {
            setData(response);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setError(
              error?.response?.data?.errors[0] || error?.response?.data?.error
            );
          });
      }
    },
    [authUser?.access_token]
  );

  const deleteCategory = useCallback(
    (category) => {
      if (category) {
        setLoading(true);
        Config.PROD_REMOTE_API.delete(`categories/${category}`, {
          headers: { Authorization: `Bearer ${authUser?.access_token}` },
        })
          .then((res) => res.data)
          .then((response) => {
            setData({ ...response, _id: category });
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setError(error?.response?.data?.errors[0]);
          });
      }
    },
    [authUser?.access_token]
  );

  const updateCategory = useCallback(
    (categories = []) => {
      if (categories) {
        setLoading(true);
        const promises = categories?.map((category) =>
          Config.PROD_REMOTE_API.put(
            `categories/${category?._id}/sort?newSortValue=${category?.sortOrder}`,
            {}, // providing request body is deprecated - new endpoint
            {
              headers: { Authorization: `Bearer ${authUser?.access_token}` },
            }
          )
        );
        const allSettled = Promise.allSettled(promises);
        allSettled
          .then((response) => {
            setLoading(false);
            setData(
              [...response?.values()]
                .map(({ status, reason, value }) => {
                  if (status === "fulfilled") {
                    const {
                      data: { lastModifiedDate, createdDate, ...rest },
                    } = value;

                    return {
                      ...rest,
                      lastModifiedDate:
                        algorithms?.formatDate?.toLocaleDateString(
                          lastModifiedDate
                        ),
                      createdDate:
                        algorithms.formatDate.toLocaleDateString(createdDate),
                    };
                  }
                  if (status === "rejected") {
                    setError({ message: reason?.response?.data?.errors[0] });
                    return undefined;
                  }
                  return undefined;
                })
                .filter((value) => value !== undefined)
            );
          })
          .catch((error) => {
            setLoading(false);
            setError(error?.response?.data?.errors[0]);
          });
      }
    },
    [authUser?.access_token]
  );

  return {
    loading,
    data,
    error,
    createCategory,
    updateCategory,
    deleteCategory,
  };
};

export default useCategoryMutation;
