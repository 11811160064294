import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import formFieldResolvers from "../../common/forms/resolvers";
import TextInputField from "../common/forms/fields/input.field";
import useResetPassword from "../../common/data/hooks/useResetPassword";
import toast from "react-hot-toast";

const UserResetPassword = () => {
  const { resetPassword, error, message, loading } = useResetPassword();
  const { token } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: formFieldResolvers.resetPasswordSchema,
  });

  const handleSubitReset = (data) => {
    resetPassword(data.newPassword, token);
  };

  useEffect(() => {
    if (message) {
      toast.success("Password reset successfully");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [message]);

  return (
    <div className="z-10 sm:top-8 sm:w-[30%] p-8 pt-2 max-sm:mt-8 max-sm:h-screen items-center divide-y divide-gray-100 rounded-lg shadow-md max-sm:shadow-none max-sm:flex-col max-sm:justify-center dark:bg-gray-700 dark:divide-gray-600">
      <div className="flex flex-col">
        <div className="relative flex py-6 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 text-gray-400">Reset password</span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div className="flex flex-col text-red-500">{error}</div>
        <div>
          <form
            className="flex flex-col max-sm:text-sm"
            onSubmit={handleSubmit(handleSubitReset)}
          >
            <TextInputField
              register={() => register("newPassword")}
              type="password"
              label="New passwrd"
              placeholder="Enter new password.."
              ariaPlaceholder="Enter account username"
              inputFieldError={errors.newPassword}
            />

            <TextInputField
              register={() => register("confirmPassword")}
              type="password"
              label="Confirm Password"
              placeholder="Re-enter password..."
              ariaPlaceholder="Re-enter password..."
              inputFieldError={errors.confirmPassword}
            />
            <input
              type="submit"
              value={loading ? "Resetting..." : "Reset"}
              className="px-3 py-1.5 max-sm:py-2 text-2xl max-sm:text-sm text-white font-semibold rounded-md shadow-sm ring-1 ring-inset bg-green-600 hover:bg-green-700 hover:cursor-pointer sm:text-sm sm:leading-6"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserResetPassword;
