export const SORT_DIRECTION = {
  ASC: 0,
  DESC: 1,
};

export const ACCOUNT_ROLE = {
  ROLE_AUTHOR: "ROLE_AUTHOR",
  ROLE_EDITOR: "ROLE_EDITOR",
  ROLE_PUBLISHER: "ROLE_PUBLISHER",
  ROLE_ADMIN: "ROLE_ADMIN",
};

export const STATUS = {
  DRAFT: "DRAFT",
  FOR_REVIEW: "FOR_REVIEW",
  REVIEWED: "REVIEWED",
  PUBLISHED: "PUBLISHED",
};

export const InforType = {
  CATEGORY: "categories",
  STAKEHOLDER: "stakeholders",
};
