import { useCallback, useMemo, useState } from "react";
import Config from "../../../common/data/api.service";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const useUserMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const authUser = useAuthUser();

  const headers = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${authUser?.access_token}`,
      },
    }),
    [authUser]
  );

  const onUserCreateOrUpdateAccount = useCallback(
    async ({ password, ...values }, isEditing, userToUpdate) => {
      setLoading(true);
      const apirequest = isEditing
        ? Config.PROD_REMOTE_API.put(
            `users/${userToUpdate?.uuid}`,
            { ...values, password: password.trim() === null ? null : null },
            headers
          )
        : Config.PROD_REMOTE_API.post(
            `users`,
            { ...values, password },
            headers
          );

      apirequest
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((err) => {
          setLoading(false);
          setError(err?.response?.data?.errors[0]);
        });
    },
    [headers]
  );

  const onUserChangeAccountStatus = useCallback(
    async (userToDelete, state) => {
      setLoading(true);
      Config.PROD_REMOTE_API.put(
        state
          ? `users/${userToDelete?.uuid}/enable`
          : `users/${userToDelete?.uuid}/disable`,
        {},
        headers
      )
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((err) => {
          // const error = err?.response?.data;
          setLoading(false);
          setError(err?.message);
        });
    },
    [headers]
  );

  const onUserChangeResetPassword = useCallback(
    async (email) => {
      setLoading(true);
      Config.PROD_REMOTE_API.post(
        `users/forgot-password?email=${email}`,
        {},
        headers
      )
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((err) => {
          // const error = err?.response?.data;
          setLoading(false);
          setError(err?.message);
        });
    },
    [headers]
  );

  const onUserDeleteAccount = useCallback(
    async (userToDelete) => {
      setLoading(true);
      Config.PROD_REMOTE_API.delete(`users/${userToDelete?.uuid}`, {}, headers)
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((err) => {
          const error = err?.response?.data;
          setLoading(false);
          setError(`${error?.error}: ${error?.message}`);
        });
    },
    [headers]
  );

  const onResetPassword = useCallback(
    async (email) => {
      setLoading(true);
      Config.PROD_REMOTE_API.post(
        `users/forgot-password?email${email}`,
        {},
        headers
      )
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((err) => {
          const error = err?.response?.data;
          setLoading(false);
          setError(`${error?.error}: ${error?.message}`);
        });
    },
    [headers]
  );

  return {
    loading,
    data,
    error,
    onUserCreateOrUpdateAccount,
    onUserDeleteAccount,
    onUserChangeAccountStatus,
    onResetPassword,
    onUserChangeResetPassword,
  };
};

export default useUserMutation;
