import {
  AtSymbolIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import HTMLReactParser from "html-react-parser/lib/index";
import React from "react";
import { useLocation } from "react-router-dom";

export const StakeholderContact = ({ name, children }) => (
  <div className="relative flex gap-x-3">
    <div className="flex h-6 items-center">{children}</div>
    <div className="text-sm leading-6">
      <label htmlFor="comments" className="font-medium text-gray-600">
        {name}
      </label>
      <p className="text-gray-500"></p>
    </div>
  </div>
);

const StakeholderDetails = () => {
  const location = useLocation();
  const { stakeholder } = location?.state;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="divide-y rounded-lg w-full flex-row max-w-8xl mx-auto">
        <div className="border-none border-gray-900/10 py-8">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {stakeholder?.name}
          </h2>
          <div className="font-normal leading-8 text-gray-600">
            {HTMLReactParser(stakeholder?.about)}
          </div>

          <div className="mt-10 space-y-10">
            {stakeholder?.vision && (
              <div>
                <legend className="text-base font-semibold leading-6 text-green-950">
                  Vision
                </legend>
                <div className="mt-3 space-y-3">
                  <p className="text-gray-600 leading-8">
                    {HTMLReactParser(stakeholder?.vision)}
                  </p>
                </div>
              </div>
            )}

            {stakeholder?.contacts && (
              <div>
                <legend className="text-base font-semibold leading-6 text-green-950">
                  Contacts
                </legend>
                <p className="mt-1 text-md leading-6 text-gray-500">
                  These are the official contact details you might need to reach
                  out.
                </p>
                <div className="mt-6 space-y-6 leading-8">
                  {stakeholder?.contacts?.email && (
                    <StakeholderContact name={stakeholder?.contacts?.email}>
                      <AtSymbolIcon className="h-5 w-5 rounded-sm text-green-900" />
                    </StakeholderContact>
                  )}

                  {stakeholder?.contacts?.telephone && (
                    <StakeholderContact name={stakeholder?.contacts?.telephone}>
                      <PhoneIcon className="h-5 w-5 rounded-sm text-green-900" />
                    </StakeholderContact>
                  )}

                  {stakeholder?.contacts?.website && (
                    <StakeholderContact name={stakeholder?.contacts?.website}>
                      <GlobeAltIcon className="h-5 w-5 rounded-sm text-green-900" />
                    </StakeholderContact>
                  )}

                  {stakeholder?.contacts?.address && (
                    <StakeholderContact name={stakeholder?.contacts?.address}>
                      <MapPinIcon className="h-5 w-5 rounded-sm text-green-900" />
                    </StakeholderContact>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeholderDetails;
