import React, { Fragment } from "react";
import FieldError from "./field.error";

const SelectInputField = ({
  label,
  value,
  disabled,
  placeholder,
  options = [],
  handleChange,
  inputFieldError,
  ...props
}) => {
  return (
    <Fragment>
      <select
        {...props}
        onChange={handleChange}
        value={value}
        className={[
          "max-sm:text-sm px-3 block w-auto min-w-[50%] max-sm:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-300 sm:text-sm sm:leading-6",
          disabled ? " bg-gray-200 cursor-not-allowed " : "",
        ].join(" ")}
      >
        <option key="_default_">{placeholder}</option>
        {options?.map((option, index) => {
          return (
            <option key={index} value={option?.toLowerCase()}>
              {option}
            </option>
          );
        })}
      </select>
      <FieldError inputFieldError={inputFieldError} />
      <span className="mb-2"></span>
    </Fragment>
  );
};

export default SelectInputField;
