import Axios from "axios";

/**
 * Global configuration file for the api baseUrl
 *
 * To be removed once proper state management is put in place
 */
const Config = (() => {
  const REMOTE_PROD_SERVER = "https://minigrids.energy.gov.mw/api/v1";
  // const REMOTE_PROD_SERVER = "http://localhost:8080";

  const PROD_REMOTE_API = Axios.create({
    baseURL: REMOTE_PROD_SERVER,
  });

  return {
    PROD_REMOTE_API,
  };
})();

export default Config;
