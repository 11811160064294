import { useCallback, useState } from "react";
import Config from "../../../common/data/api.service";
const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(null);

  const resetPassword = useCallback(async (newPassword, token) => {
    setLoading(true);
    await Config.PROD_REMOTE_API.post(
      `users/reset-password?newPassword=${newPassword}&token=${token}`,
      {}
    )
      .then((res) => {
        setLoading(false);
        setMessage(res.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error?.response?.data?.errors[0] || error?.message);
        throw new Error(error?.message);
      });
  });

  return { loading, error, resetPassword, message };
};

export default useResetPassword;
