import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import UserBasicDetailForm from "./user-details.form";
import UserRolesForm from "./user-roles.form";
import { useRecoilValue } from "recoil";
import { usersState } from "../../common/state/state";

const UserProfilePage = () => {
  const [userToUpdate, setUserToUpdate] = useState();
  const params = useParams();
  const authUser = useAuthUser();

  const registeredAccounts = useRecoilValue(usersState);

  useEffect(() => {
    (() => {
      if (params?.accountId && registeredAccounts) {
        setUserToUpdate(
          registeredAccounts?.filter(
            (user) => user?.uuid === params?.accountId
          )[0]
        );
      }
    })();
  }, [params, authUser, registeredAccounts]);

  return (
    <div className="flex flex-col">
      <UserBasicDetailForm
        userToUpdate={userToUpdate}
        setUserToUpdate={setUserToUpdate}
        buttonText="Save changes"
      />

      <hr className="my-3" />

      <UserRolesForm
        userToUpdate={userToUpdate}
        setUserToUpdate={setUserToUpdate}
      />
    </div>
  );
};

export default UserProfilePage;
