import { Popover } from "antd";
import { checkPropTypes } from "prop-types";
import { CONTEXT_MENU_ACTION } from "../../../common/utils/constants";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

export const CMSContextMenu = ({
  items,
  element,
  onEdit,
  onView,
  onDelete,
  onDisable,
  onEnable,
}) => {
  const handleContextMenuAction = (e) => {
    const key = e.currentTarget.id.split("_")[0];
    const rowId = e.currentTarget.id.split("_")[1];

    switch (key) {
      case CONTEXT_MENU_ACTION.EDIT:
        return onEdit(rowId);
      case CONTEXT_MENU_ACTION.DETAILS:
        return onView(rowId);
      case CONTEXT_MENU_ACTION.DELETE:
        return onDelete(rowId);
      case CONTEXT_MENU_ACTION.DISABLE:
        return onDisable(rowId);
      case CONTEXT_MENU_ACTION.ENABLE:
        return onEnable(rowId);
      default:
        throw new Error("Unknown context menu action");
    }
  };

  const content = (
    <div className="flex flex-col h-fit w-20 gap-2">
      {items.map((item, index) => (
        <div
          className="text-gray-500 hover:text-gray-700  hover:cursor-pointer p-1"
          key={`${index}_${element}`}
          id={`${item.label}_${element}`}
          onClick={handleContextMenuAction}
        >
          <span className="flex flex-row gap-2">
            <span className="h-4 w-5">{item.icon}</span>
            {item.label}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <Popover
        placement="bottomRight"
        content={content}
        trigger={"click"}
        className=""
        arrow={false}
      >
        <div className="text-gray-500 font-bold hover:text-gray-900 hover:cursor-pointer">
          <EllipsisVerticalIcon className="w-8 h-8" />
        </div>
      </Popover>
    </div>
  );
};

CMSContextMenu.propTypes = checkPropTypes();
