import toast, { LoaderIcon } from "react-hot-toast";
import ButtonInputField from "../common/forms/fields/input.button";
import SelectInputField from "../common/forms/fields/input.select";
import FormSection from "../common/forms/sections";
import ShapefilePicker from "./cms.shapefile.picker";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../common/forms/resolvers";
import { useEffect, useState } from "react";
import { GIS_RESOURCE_TYPES } from "../../common/utils/gis.resource.types";
import useShapefileMutation from "../../common/data/hooks/useShapefileMutation";
import { ENERGY_RESOURCE_TYPES } from "../../common/utils/energy.resource.types";

const UploadGisForm = ({ regions, districts }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: formFieldResolvers.newShapefileForm,
  });
  const [selectedGisType, setSelectedGisType] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [
    selectedDistrictForEnergyResource,
    setSelectedDistrictForEnergyResource,
  ] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [areAllFileUploadParamsSelected, setAreAllFileUploadParamsSelected] =
    useState(false);
  const [energyResourceType, setEnergyResourceType] = useState();
  const {
    sendShapeFile,
    error: shapefileUploadError,
    data: shapefileUploadResponse,
    loading: shapefileUploadProgress,
  } = useShapefileMutation();

  const handleGisSubmission = () => {
    switch (selectedGisType) {
      case "region boundary":
        const regionId = regions.find(
          (region) => region.name.toLowerCase() === selectedRegion.toLowerCase()
        )?._id;
        sendShapeFile(`/regions/${regionId}/upload-boundary`, selectedFile);
        break;
      case "district boundary":
        const districtId = districts.find(
          (district) =>
            district.properties.name.toLowerCase() ===
            selectedDistrict.toLowerCase()
        )?._id;
        sendShapeFile(`/districts/${districtId}/upload-boundary`, selectedFile);
        break;
      case "energy":
        const ditrictForGeojson = districts.find(
          (district) =>
            district.properties.name.toLowerCase() ===
            selectedDistrictForEnergyResource.toLowerCase()
        )?._id;
        const selectedResource = ENERGY_RESOURCE_TYPES.find(
          (resource) =>
            resource.name.toLowerCase() === energyResourceType.toLowerCase()
        );
        const path = `/districts/${ditrictForGeojson}/${selectedResource.path}`;
        sendShapeFile(path, selectedFile);
        break;
    }
  };

  const resetState = () => {
    setSelectedGisType("");
    setSelectedRegion();
    setSelectedDistrict();
    setSelectedFile();
    setAreAllFileUploadParamsSelected();
    setSelectedDistrictForEnergyResource();
    setEnergyResourceType();
  };

  useEffect(() => {
    if (shapefileUploadError) {
      const errors = shapefileUploadError?.response?.data?.errors?.reduce(
        (err) => err
      );
      if (errors) {
        toast.error("Error: " + errors);
      } else {
        toast.error("Failed to upload boundary");
      }
      resetState();
    }

    if (shapefileUploadResponse) {
      toast.success("File Upload Success");
      resetState();
    }
  }, [shapefileUploadResponse, shapefileUploadError]);
  return (
    <form
      onSubmit={handleSubmit(handleGisSubmission)}
      className="flex flex-col w-full"
    >
      <SelectInputField
        placeholder="Select gis type..."
        value={selectedGisType}
        options={GIS_RESOURCE_TYPES.map((resource) => resource)}
        handleChange={(e) => {
          setSelectedGisType(e.target.value);
        }}
        inputFieldError={errors.name}
      />
      {(() => {
        switch (selectedGisType) {
          case "region boundary":
            return (
              <SelectInputField
                placeholder="Select region..."
                value={selectedRegion}
                handleChange={(e) => {
                  setSelectedRegion(e.target.value);
                }}
                options={regions.map((region) => region.name).sort()}
                inputFieldError={errors.name}
              />
            );
          case "district boundary":
            return (
              <SelectInputField
                placeholder="Select district..."
                value={selectedDistrict}
                options={districts
                  .map((district) => district.properties.name)
                  .sort()}
                handleChange={(e) => {
                  setSelectedDistrict(e.target.value);
                }}
                inputFieldError={errors.name}
              />
            );
          case "energy":
            return (
              <>
                <SelectInputField
                  placeholder="Select district..."
                  value={selectedDistrictForEnergyResource}
                  options={districts
                    .map((district) => district.properties.name)
                    .sort()}
                  handleChange={(e) => {
                    setSelectedDistrictForEnergyResource(e.target.value);
                  }}
                  inputFieldError={errors.name}
                />
                {selectedDistrictForEnergyResource && (
                  <SelectInputField
                    placeholder="Energy resource type..."
                    value={energyResourceType}
                    handleChange={(e) => {
                      setEnergyResourceType(e.target.value);
                    }}
                    options={ENERGY_RESOURCE_TYPES.map(
                      (resourceType) => resourceType.name
                    )}
                    inputFieldError={errors.name}
                  />
                )}
              </>
            );
        }
      })()}
      {(selectedDistrict ||
        selectedRegion ||
        (selectedDistrictForEnergyResource && energyResourceType)) && (
        <ShapefilePicker
          handleFileSelectionChange={(e) => {
            setSelectedFile(e.target.files[0]);
            setAreAllFileUploadParamsSelected(true);
          }}
          selectedFile={selectedFile}
          isUploading={shapefileUploadProgress}
        />
      )}

      <FormSection className="py-1.5 flex-col" show={true}>
        <div className="flex flex-row">
          {areAllFileUploadParamsSelected && (
            <ButtonInputField
              type="submit"
              value={"Save changes"}
              disabled={shapefileUploadProgress}
            />
          )}
        </div>
      </FormSection>
    </form>
  );
};

export default UploadGisForm;
