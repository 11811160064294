import * as yup from "yup";

export const newQuestionSchema = yup
  .object({
    name: yup.string().required(),
    shortName: yup.string().required(),
    about: yup.string().required(),
  })
  .required();

export const updateQuestionSchema = yup
  .object({
    name: yup.string(),
    shortName: yup.string(),
    about: yup.string(),
  })
  .required();
