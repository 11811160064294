import React from "react";
import createStore from "react-auth-kit/createStore";
import AuthProvider from "react-auth-kit/AuthProvider";
import Config from "../data/api.service";
import createRefresh from "react-auth-kit/createRefresh";

const refresh = createRefresh({
  interval: 10, // The time in sec to refresh the Access token,
  refreshApiCallback: async (param) => {
    try {
      const response = await Config.PROD_REMOTE_API.get("users/refresh-token", {
        headers: { Authorization: `Bearer ${param.refreshToken}` },
      });

      return {
        isSuccess: true,
        newAuthToken: response.data.access_token,
        newAuthTokenExpireIn: 10,
        newRefreshTokenExpiresIn: 60,
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
      };
    }
  },
});

const store = createStore({
  authName: "__auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
  refresh,
});

const UserAuthProvider = ({ children }) => {
  return <AuthProvider store={store}>{children}</AuthProvider>;
};

export default UserAuthProvider;
