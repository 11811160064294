import React from "react";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";

const CategoryApprovalStatus = ({ category, approvable }) => {
  return (
    approvable && (
      <span>
        {category.isPublished && (
          <span className="text-xs flex flex-auto ml-1">
            <CheckCircleIcon className="h-4 w-fit text-green-700" /> published
          </span>
        )}

        {category.isDraft && (
          <span className="text-xs flex flex-auto ml-1">
            <QuestionMarkCircleIcon className="h-4 w-fit text-orange-800" />
            draft
          </span>
        )}

        {category.isBeingReviewed && (
          <span className="text-xs flex flex-auto ml-1">
            <QuestionMarkCircleIcon className="h-4 w-fit text-orange-600" />{" "}
            under review
          </span>
        )}
        {category.isReviewed && (
          <span className="text-xs flex flex-auto ml-1">
            <QuestionMarkCircleIcon className="h-4 w-fit text-orange-400" />{" "}
            reviewed
          </span>
        )}
        {category.isArchived && (
          <span className="text-xs flex flex-auto ml-1">
            <QuestionMarkCircleIcon className="h-4 w-fit text-orange-300" />{" "}
            archived
          </span>
        )}
      </span>
    )
  );
};

export default CategoryApprovalStatus;
