import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import { publicRoutes } from "./public.routes";
import { privateRoutes } from "./private.routes";
import { AuthRoleProvider } from "./auth.route.context";

const navRouter = createBrowserRouter(
  createRoutesFromElements([
    publicRoutes?.map(({ path, element, children }, index) => (
      <Route
        path={path}
        element={element}
        children={children?.map((child, index0) => (
          <Route
            key={`path-${index}${index0}`}
            path={child.path}
            element={child.element}
          />
        ))}
      />
    )),
    privateRoutes?.map(({ path, element, children }, index) => (
      <Route
        path={path}
        element={<RequireAuth fallbackPath="/login">{element}</RequireAuth>}
        children={children?.map((child, index0) => (
          <Route
            key={`path-${index}${index0}`}
            path={child.path}
            element={<AuthRoleProvider>{child.element}</AuthRoleProvider>}
            children={child?.children?.map((grandChild, index1) => (
              <Route
                key={`${index}${index0}${index1}`}
                path={grandChild.path}
                element={grandChild.element}
              />
            ))}
          />
        ))}
      />
    )),
  ])
);

export default navRouter;
