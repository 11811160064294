export const ENERGY_RESOURCE_TYPES = [
  {
    name: "Distribution line - 11KV_LINES",
    path: "distribution-lines?type=ELEVEN_KV_LINES",
  },
  {
    name: "Distribution line - 400KV_LINES",
    path: "distribution-lines?type=FOUR_HUNDRED_KV_LINES",
  },
  {
    name: "Distribution line - 33KV_LINES",
    path: "distribution-lines?type=THIRTY_THREE_KV_LINES",
  },
  {
    name: "Marep Centers - ELECTRIFIED",
    path: "marep-centers?type=ELECTRIFIED",
  },
  {
    name: "Marep Centers - TO_BE_ELECTRIFIED",
    path: "marep-centers?type=TO_BE_ELECTRIFIED",
  },

  { name: "Minigrids - EXISTING", path: "minigrids?type=EXISTING" },
  { name: "Minigrids - POTENTIAL", path: "minigrids?type=POTENTIAL" },

  { name: "Substations", path: "substations" },
  { name: "Transformers - GROUND", path: "transformers?position=GROUND" },
  { name: "Transformers - OVERHEAD", path: "transformers?position=OVERHEAD" },
];
