import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { TableStakeholder } from "../common/tables/stakeholder.table";
import ToolBar from "../common/tables/toolbar";
import SearchInputField from "../common/forms/fields/search.input";
import { menuItems } from "../common/menus/menu.items";
import ProgressLoader from "../common/progress";
import ConfirmActionManager from "../common/alerts/alert.confirmation";

export const CMSStakeholderList = ({
  loading,
  stakeholders,
  onDelete,
  categoryPage,
  recoilCategoryStateKey,
  // setSelectedCategoryToView,
  searchPlaceholder,
}) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [filtererdStakeholders, setFilteredStakeholders] = useState([]);
  const [isDeletingRow, setDeletingRow] = useState(false);
  const [stakeholderToDelete, setStakeholderToDelete] = useState(null);

  const navigate = useNavigate();
  const setCmsSelectedCategory = useSetRecoilState(recoilCategoryStateKey);

  useEffect(() => {
    if (searchTerm?.length > 0) {
      setFilteredStakeholders(
        stakeholders?.filter((stakeholder) =>
          stakeholder?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase().trim())
        )
      );
    } else {
      setFilteredStakeholders(stakeholders);
    }
  }, [searchTerm, stakeholders]);

  const tableColumns = ["Photo", "name", "Description", "actions"];

  const handleClick = (stakeholder) => {
    //set selectedCategory to state. This will be accessible to the details view
    setCmsSelectedCategory(stakeholder);
    // setSelectedCategoryToView(stakeholder);
    navigate(`/auth/${categoryPage}/${stakeholder?._id}/details`);
  };

  return (
    <Fragment>
      <div className="relative min-w-full h-full overflow-x-auto shadow-none sm:rounded-lg">
        <ToolBar>
          <div className="flex flex-row pb-3 w-full justify-between">
            <div className="flex flex-row relative py-0 items-start justify-start">
              <SearchInputField
                name={searchPlaceholder}
                handleOnSearch={(e) => setSearchTerm(e.target.value)}
                placeholder={`Search for ${searchPlaceholder}...`}
              />
              <button className="flex-row pl-2 py-2.5 text-gray-600 hover:text-gray-800"></button>
            </div>
            <div></div>
          </div>
          <div></div>
        </ToolBar>
        <ProgressLoader loading={loading} />
        <ConfirmActionManager
          loading={loading}
          isAlertOpen={isDeletingRow}
          handleAlertCancel={(e) => setDeletingRow(false)}
          handleAlertConfirm={(e) => {
            onDelete(stakeholderToDelete);
            setDeletingRow(!isDeletingRow);
          }}
        />
        <TableStakeholder
          stakeholders={filtererdStakeholders}
          tableColumns={tableColumns}
          // filter based on authenticated user role allow performing an action on a category
          menuItems={menuItems}
          handleClick={handleClick}
          onView={(uuid) =>
            navigate(`/auth/${categoryPage}/${uuid}/details`, {
              state: { page: categoryPage }, // used to refetch all subcategories
            })
          }
          onDelete={(uuid) => {
            setStakeholderToDelete(uuid);
            setDeletingRow(true);
          }}
        />
      </div>
    </Fragment>
  );
};

export default CMSStakeholderList;
