import { MapContainer, GeoJSON, TileLayer, useMap, Popup } from "react-leaflet";
import ProgressLoader from "../common/progress";
import GisSelectionForm from "./cms.gis.selection.form";
import { useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/24/solid";
import ConfirmActionManager from "../common/alerts/alert.confirmation";
import L, { divIcon } from "leaflet";
import useShapefileMutation from "../../common/data/hooks/useShapefileMutation";
import toast from "react-hot-toast";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { USER_ROLE } from "../../common/utils/constants";

const RecenterMap = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);
  return null;
};

const CMSMapContent = ({
  loading,
  activeTab,
  regions,
  districts,
  handleGisSelectionChange,
  handleSelectedDistrictForGis,
  selectedDistrictForEnergyResource,
  selectedErgyResource,
  region,
  district,
  districtFeatures,
}) => {
  const [centroid, setCentroid] = useState([33.7741195, -12.99]);
  const [zoomLevel, setZoomLevel] = useState(7);
  const [deleteMessage, setDeleteMessage] = useState();
  const [showConfirmDialog, setShowConfirmationDialog] = useState(false);
  const {
    deleteGisData,
    loading: gisDataDeletionProgress,
    error: deleteGisDataError,
    data: deleteGisDataResponse,
  } = useShapefileMutation();
  const authUser = useAuthUser();
  const [showDeleteIcon, setShowDeleteicon] = useState(false);
  const [regionData, setRegionData] = useState(region);
  const [districtData, setDistrictData] = useState(district);
  const [districtfetureData, setDistrictFetureData] =
    useState(districtFeatures);

  const initiateDeleteGisData = () => {
    switch (activeTab) {
      case "region":
        if (region?.polygons?.features?.length > 0) {
          setDeleteMessage(
            `This will remove ${regionData?.properties?.name} boundary. The action is not reversible`
          );
          setShowConfirmationDialog(true);
        }
        break;
      case "district":
        if (districtData?.polygons?.features?.length > 0) {
          setDeleteMessage(
            `This will remove ${districtData?.properties?.name} boundary. The action is not reversible`
          );
          setShowConfirmationDialog(true);
        }
        break;

      case "energy":
        if (districtfetureData?.polygons?.features?.length > 0) {
          setDeleteMessage(
            `This will remove ${selectedErgyResource?.name} from ${selectedDistrictForEnergyResource}. The action is not reversible`
          );
          setShowConfirmationDialog(true);
        }
        break;
    }
  };

  const handleDeleteGisData = () => {
    switch (activeTab) {
      case "region":
        if (regionData?.polygons?.features?.length > 0) {
          setShowConfirmationDialog(false);
          deleteGisData(`/regions/${region?._id}/delete-boundary`);
        }
        break;

      case "district":
        if (districtData?.polygons?.features?.length > 0) {
          setShowConfirmationDialog(false);
          deleteGisData(`/districts/${district?._id}/delete-boundary`);
        }
        break;

      case "energy":
        if (districtfetureData?.polygons?.features?.length > 0) {
          setShowConfirmationDialog(false);
          const districtId = districts.find(
            (d) =>
              d?.properties?.name.toLowerCase() ===
              selectedDistrictForEnergyResource.toLowerCase()
          )?._id;
          setShowConfirmationDialog(false);
          deleteGisData(
            `/districts/${districtId}/${selectedErgyResource?.path}`
          );
        }
        break;
    }
  };
  useEffect(() => {
    switch (activeTab) {
      case "district":
        if (districtData) {
          setZoomLevel(9);
          districtData?.centroids?.coordinates
            ? setCentroid(districtData?.centroids?.coordinates)
            : setCentroid([33.7741195, -12.99]);
        }
        break;
      case "region":
        if (regionData) {
          setZoomLevel(7);
          setCentroid([33.7741195, -12.99]);
        }
        break;
      case "energy":
        if (districtData) {
          setZoomLevel(9);
          districtData?.centroids?.coordinates
            ? setCentroid(districtData?.centroids?.coordinates)
            : setCentroid([33.7741195, -12.99]);
        }
        break;
    }
  }, [activeTab, districtData, regionData, setZoomLevel, setCentroid]);

  useEffect(() => {
    switch (activeTab) {
      case "region":
        regionData?.polygons?.features?.length > 0
          ? setShowDeleteicon(true)
          : setShowDeleteicon(false);
        break;
      case "district":
        districtData?.polygons?.features?.length > 0
          ? setShowDeleteicon(true)
          : setShowDeleteicon(false);
        break;

      case "energy":
        districtfetureData?.polygons?.features?.length > 0
          ? setShowDeleteicon(true)
          : setShowDeleteicon(false);
        break;
      default:
        setShowDeleteicon(false);
    }
  }, [regionData, districtData, districtfetureData]);

  useEffect(() => {
    if (region) {
      setRegionData(region);
    }

    if (district) {
      setDistrictData(district);
    }

    if (districtFeatures) {
      setDistrictFetureData(districtFeatures);
    }
  }, [
    setRegionData,
    region,
    setDistrictData,
    district,
    setDistrictFetureData,
    districtFeatures,
  ]);

  const customMarkerIcon = (name) =>
    divIcon({
      html: name,
      className: "icon",
    });

  const setIcon = ({ properties }, latlng) => {
    return L.marker(latlng, { icon: customMarkerIcon(properties.gid) });
  };

  useEffect(() => {
    if (deleteGisDataResponse || deleteGisDataResponse === "") {
      switch (activeTab) {
        case "region":
          toast.success(
            `${regionData?.properties?.name} boundary removed successfully`
          );
          setRegionData({});
          break;
        case "district":
          toast.success(
            `${districtData?.properties?.name} boundary removed successfully`
          );
          setDistrictData({});
          break;
        case "energy":
          toast.success(
            `${selectedErgyResource?.name} successfully removed from ${selectedDistrictForEnergyResource}`
          );
          setDistrictFetureData({});
          break;
      }
    }

    if (deleteGisDataError) {
      switch (activeTab) {
        case "region":
          toast.error(
            `Failed to remove ${regionData?.properties?.name} boundary.`
          );
          break;
        case "district":
          toast.error(`Failed to remove ${districtData?.properties?.name}`);
          break;
        case "energy":
          break;
      }
    }
  }, [
    deleteGisDataResponse,
    deleteGisDataError,
    setRegionData,
    setDistrictData,
    setDistrictFetureData,
  ]);

  const onEachFeatureClick = (feature, layer) => {
    if (feature.properties) {
      const allFetures = `<ul>${Object.entries(feature.properties).flatMap(
        (property) => {
          return `<li>${property[0]} : <b>${property[1]}</b></li>`;
        }
      )}</ul>`.replaceAll(",", "");
      layer.bindPopup(allFetures);
    }
  };
  return (
    <div className="flex flex-col text-gray-500 dark:text-gray-400">
      <ProgressLoader loading={loading || gisDataDeletionProgress} />
      <ConfirmActionManager
        isAlertOpen={showConfirmDialog}
        handleAlertConfirm={handleDeleteGisData}
        handleAlertCancel={() => setShowConfirmationDialog(false)}
        alertMessage={deleteMessage}
      />
      <GisSelectionForm
        activeTab={activeTab}
        districts={districts}
        regions={regions}
        handleGisSelectionChange={handleGisSelectionChange}
        handleSelectedDistrictForGis={handleSelectedDistrictForGis}
        selectedDistrictForEnergyResource={selectedDistrictForEnergyResource}
        disbaleEnergyResource={loading}
      />

      {showDeleteIcon && authUser?.roles.includes(USER_ROLE.ADMIN) && (
        <div className="absolute z-40 top-44 left-2 bg-white p-1 border-2 border-black-600">
          <TrashIcon
            className="h-5 w-fit text-orange-800 m-1 cursor-pointer"
            onClick={initiateDeleteGisData}
          />
        </div>
      )}

      <MapContainer
        center={centroid}
        // scrollWheelZoom={true}
        style={{ height: "70vh", zIndex: "0" }}
        key={centroid}
        zoom={zoomLevel} // Zoom level to focus on the country
        className="w-full h-full" // Set the size of the map
        maxBoundsViscosity={1.0}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <RecenterMap lat={centroid?.[1]} lng={centroid?.[0]} />

        <GeoJSON
          data={regionData?.polygons}
          key={regionData?._id}
          style={{ weight: 1, color: "lime" }}
          onEachFeature={onEachFeatureClick}
        />
        <GeoJSON
          data={districtData?.polygons}
          key={districtData?._id}
          style={{ weight: 1, color: "red" }}
          onEachFeature={onEachFeatureClick}
        />
        <GeoJSON
          data={districtfetureData?.polygons}
          key={new Date().getMilliseconds()}
          style={{ weight: 2, color: "green" }}
          // pointToLayer={setIcon}
          onEachFeature={onEachFeatureClick}
        />
      </MapContainer>
    </div>
  );
};

export default CMSMapContent;
