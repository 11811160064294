import React, { Fragment, useEffect, useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useStakeholderMutation from "../../common/data/hooks/useStakeholderMutation";
import toast from "react-hot-toast";
import Button from "../common/buttons";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  AtSymbolIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import formFieldResolvers from "../../common/forms/resolvers";
import { useForm } from "react-hook-form";
import TextInputField from "../common/forms/fields/input.field";
import ButtonInputField from "../common/forms/fields/input.button";
import { StakeholderContact } from "./stakeholder.details";

const ContactDetailsSection = ({ stakeholder }) => {
  return (
    <div className="border-none border-gray-900/10 pb-8">
      <div className="mt-2 space-y-10">
        <div className="mt-6 space-y-6">
          {stakeholder?.contacts?.email && (
            <StakeholderContact name={stakeholder?.contacts?.email}>
              <AtSymbolIcon className="h-5 w-5 rounded-sm text-green-800" />
            </StakeholderContact>
          )}

          {stakeholder?.contacts?.telephone && (
            <StakeholderContact name={stakeholder?.contacts?.telephone}>
              <PhoneIcon className="h-5 w-5 rounded-sm text-green-800" />
            </StakeholderContact>
          )}

          {stakeholder?.contacts?.website && (
            <StakeholderContact name={stakeholder?.contacts?.website}>
              <GlobeAltIcon className="h-5 w-5 rounded-sm text-green-800" />
            </StakeholderContact>
          )}

          {stakeholder?.contacts?.address && (
            <StakeholderContact name={stakeholder?.contacts?.address}>
              <MapPinIcon className="h-5 w-5 rounded-sm text-green-800" />
            </StakeholderContact>
          )}
        </div>
      </div>
    </div>
  );
};

const ContactUpdateFormSection = ({
  register,
  handleSubmit,
  handleFormSubmit,
  isSubmitting,
  isValid,
  errors,
}) => {
  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="border-gray-900/10"
    >
      <div className="mt-0 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
        <div className="sm:col-span-2">
          <TextInputField
            register={() => register("email")}
            type="email"
            label="Email *"
            placeholder="Enter official email..."
            ariaPlaceholder="Enter official email"
            inputFieldError={errors?.email}
          />
        </div>

        <div className="sm:col-span-2">
          <TextInputField
            register={() => register("telephone")}
            type="text"
            label="Telephone (or Mobile) *"
            placeholder="Enter mobile number..."
            ariaPlaceholder="Enter mobile number"
            inputFieldError={errors?.telephone}
          />
        </div>

        <div className="sm:col-span-3">
          <TextInputField
            register={() => register("website")}
            type="url"
            label="Website *"
            placeholder="Enter official website..."
            ariaPlaceholder="Enter official website"
            inputFieldError={errors?.website}
          />
        </div>

        <div className="sm:col-span-4">
          <TextInputField
            register={() => register("address")}
            type="text"
            label="Address *"
            placeholder="Enter address..."
            ariaPlaceholder="Enter address"
            inputFieldError={errors?.address}
          />
        </div>
      </div>
      <div className="mt-2 w-fit h-fit py-1">
        <ButtonInputField
          type="submit"
          // disabled={!isValid || isSubmitting}
          value="Save changes"
        />
      </div>
    </form>
  );
};

const StakeholderContactInforForm = ({
  headerText,
  category,
  setUpdatedCategory,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const authUser = useAuthUser();
  const toggleEdit = () => setIsEditing((current) => !current);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    resolver: formFieldResolvers.editStakeholderContactForm,
  });
  const { data, updateStakeholder, error } = useStakeholderMutation();

  const handleFormSubmit = (values) =>
    updateStakeholder(category?._id, {
      _id: category?.contacts?._id,
      ...values,
    });

  useEffect(() => {
    ["email", "website", "address", "telephone"].map((prop) => {
      setValue(prop, category?.contacts[prop]);
      return () => null;
    });
  }, [category, setValue]);

  useEffect(() => {
    if (data) {
      if (!error) {
        setUpdatedCategory(data);
        setIsEditing(false);
        toast.success("Contacts successfully updated");
      }
    }

    if (error) {
      console.log(error);
      toast.error(JSON.stringify(error[0]));
    }
  }, [data, setIsEditing, error, setUpdatedCategory]);

  return (
    <div className="flex flex-col">
      <div className="font-medium flex items-center justify-between">
        <div className="text-gray-400 text-xs text-muted-foreground mt-2">
          {headerText}
        </div>
        {authUser?.username === category?.createdBy ? (
          <Button onClick={toggleEdit} variant="ghost">
            {isEditing && (
              <>
                <XMarkIcon className="h-4 w-4 mr-2" />
                Cancel
              </>
            )}
            {!isEditing && (
              <>
                <PlusIcon className="h-4 w-4 mr-2" />
                {!category?.contacts ? "Add contacts" : "Change contacts"}
              </>
            )}
          </Button>
        ) : null}
      </div>
      {!isEditing && (
        <Fragment>
          {!category?.contacts ? (
            <p className="text-sm mt-2 text-slate-500 italic">
              No contacts added yet
            </p>
          ) : (
            <ContactDetailsSection stakeholder={category} />
          )}
        </Fragment>
      )}
      {isEditing && (
        <ContactUpdateFormSection
          isSubmitting={isSubmitting}
          isValid={isValid}
          errors={errors}
          register={register}
          handleSubmit={handleSubmit}
          handleFormSubmit={handleFormSubmit}
          stakeholder={category}
        />
      )}
    </div>
  );
};

export default StakeholderContactInforForm;
