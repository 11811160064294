import { CATEGORY_STATUS } from "./constants";

export const CATEGORY_TABS = [
  {
    order: 0,
    name: "by_me",
    label: "Drafts",
    active: true,
    key: Object.keys(CATEGORY_STATUS)[0],
    status: CATEGORY_STATUS.DRAFT,
  },
  {
    order: 1,
    name: "for_review",
    label: "For Review",
    active: false,
    key: Object.keys(CATEGORY_STATUS)[1],
    status: CATEGORY_STATUS.FOR_REVIEW,
  },
  {
    order: 2,
    name: "reviewed",
    label: "Reviewed",
    active: false,
    key: Object.keys(CATEGORY_STATUS)[2],
    status: CATEGORY_STATUS.REVIEWED,
  },
  {
    order: 3,
    name: "published",
    label: "Published",
    active: false,
    key: Object.keys(CATEGORY_STATUS)[3],
    status: CATEGORY_STATUS.PUBLISHED,
  },
  {
    order: 4,
    name: "archived",
    label: "Archived",
    active: false,
    key: Object.keys(CATEGORY_STATUS)[4],
    status: CATEGORY_STATUS.ARCHIVED,
  },
];
