import * as yup from "yup";

export const newStakeholderSchema = yup.object({
  about: yup.string().required(),
  name: yup.string().required(),
  vision: yup.string().required(),
  address: yup.string().required(),
  email: yup.string().email().required(),
  telephone: yup.string().required(),
  website: yup.string().url().required(),
});

export const editStakeholderInforSchema = yup.object({
  about: yup.string().required(),
  name: yup.string().required(),
  vision: yup.string().required(),
});

export const editStakeholderContactSchema = yup.object({
  address: yup.string().required(),
  email: yup.string().email().required(),
  telephone: yup.string().required(),
  website: yup.string().url().required(),
});

export const newStakeholdeImageSchema = yup.object({
  image: yup.string().email().required(),
});
