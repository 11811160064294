import { useState, useEffect, useCallback } from "react";
import Config from "../api.service";
const useQueryRegionsAndDistricts = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const refetch = useCallback(async (path) => {
    setLoading(true);
    if (path !== undefined && path !== null) {
      const data = await Config.PROD_REMOTE_API.get(path)
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          const regions = data.map((region) => {
            return { name: region.properties.name, _id: region._id };
          });
          const districts = data.flatMap((region) => region.districts);
          return { regions, districts };
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setError((error) => error?.message);
        });
      setData(data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    refetch(url);
  }, [url, refetch, setLoading, setError, setData]);

  return { loading, data, error };
};

export default useQueryRegionsAndDistricts;
