import { useCallback, useState } from "react";
import Config from "../../../common/data/api.service";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { InforType } from "../constants";

const useFileMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const authUser = useAuthUser();

  const sendFiles = useCallback(
    (urlPath, files) => {
      if (urlPath !== undefined && urlPath !== null) {
        setLoading(true);
        const categoryDocuments = new FormData();
        categoryDocuments.append("file", files);

        Config.PROD_REMOTE_API.put(urlPath, categoryDocuments, {
          headers: { Authorization: `Bearer ${authUser?.access_token}` },
        })
          .then((res) => res.data)
          .then((response) => {
            setData(response);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setError(error);
          });
      }
    },
    [authUser?.access_token]
  );

  const deleteFiles = useCallback(
    (category, file, type) => {
      if (category && file) {
        setLoading(true);
        Config.PROD_REMOTE_API.delete(
          type === InforType.CATEGORY
            ? `categories/${category}/files/${file}`
            : `stakeholders/${category}/files`,
          {
            headers: { Authorization: `Bearer ${authUser?.access_token}` },
          }
        )
          .then((res) => res.data)
          .then((response) => {
            setData(response);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setError(error);
          });
      }
    },
    [authUser?.access_token]
  );

  return { loading, data, error, sendFiles, deleteFiles };
};

export default useFileMutation;
