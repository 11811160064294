import React, { useContext, useEffect, useState } from "react";
import CMSEditor from "../common/forms/editor/editor";
import FormSection from "../common/forms/sections";
import TextInputField, {
  RadioInputField,
} from "../common/forms/fields/input.field";
import ButtonInputField from "../common/forms/fields/input.button";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../common/forms/resolvers";
import { ActiveRouteContext } from "../../routes/active.route.context";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { LoaderIcon } from "react-hot-toast";
import useCategoryMutation from "../../common/data/hooks/useCategoryMutation";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { categoriesState } from "../../common/state/state";
import SelectInputField from "../common/forms/fields/input.select";
import FileDropzoneField from "../common/forms/fields/file.field";
import FileUploadManager from "../common/category/file.upload.manager";
import useQueryRegions from "../../common/data/hooks/useQueryRegionsAndDistricts";
import ShapefilePicker from "./cms.shapefile.picker";
import Config from "../../common/data/api.service";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useShapefileMutation from "../../common/data/hooks/useShapefileMutation";
import UploadGisForm from "./cms.upload.gis.form";

const CMSCreateGis = () => {
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const { data, loading, error } = useQueryRegions("/regions");
  const [districts, setDistricts] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    if (data) {
      "regions" in data ? setRegions(data.regions) : setRegions([]);
      "districts" in data ? setDistricts(data.districts) : setDistricts([]);
    }
  }, [data]);

  return (
    <div className="flex flex-col min-w-full min-h-full bg-white p-2 rounded-sm shadow-sm">
      <UploadGisForm districts={districts} regions={regions} />
    </div>
  );
};

export default CMSCreateGis;
