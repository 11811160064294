import React, { Fragment } from "react";
import { algorithms } from "../../../common/data/algorithms";
import { MinusCircleIcon } from "@heroicons/react/24/solid";

export const TableHeader = ({ tableColumns }) => {
  return (
    <thead className="bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        {tableColumns?.map((column) => (
          <th
            key={column}
            scope="col"
            className="px-3 py-3 text-gray-500 hover:text-gray-700 font-bold hover:cursor-pointer"
          >
            {algorithms.firstLetterOnlyToUpperCase(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const EnhancedTableHeader = ({
  selected,
  tableColumns,
  activeColumn,
  handleColumnSort,
  handleToggleActiveColumn,
  handleClearSelected,
}) => {
  return (
    <thead className="flex flex-row w-full py-2 pl-1 pr-2 bg-gray-100 items-center justify-between">
      {tableColumns?.map((column, index) => {
        const last = tableColumns[tableColumns.length - 1];
        const first = tableColumns[0];
        return (
          <tr
            key={index}
            className={`flex flex-row ${
              last === column ? "w-auto" : "w-full"
            } hover:cursor-pointer text-gray-500 ${
              activeColumn === column
                ? "text-gray-700 font-semibold"
                : "hover:text-gray-800"
            } hover:font-bold ${
              column === "enabled"
                ? " items-center justify-center"
                : "items-center"
            }`}
          >
            <td onClick={handleClearSelected} className="max-w-[10rem]">
              {selected > 1 && first === column ? (
                <MinusCircleIcon className="w-5 h-5 text-red-700" />
              ) : (
                <Fragment />
              )}
            </td>
            <td
              className="flex flex-row"
              id={column}
              onClick={(e) => {
                if (!(first === column && last === column)) handleColumnSort(e);
              }}
            >
              <span
                className={`${first === column ? "w-auto pt-1 pl-1" : "pt-1"}`}
              >
                {!(last === column) ? handleToggleActiveColumn(column) : null}
              </span>
              <span>{algorithms.firstLetterToUpperCase(column)}</span>
            </td>
          </tr>
        );
      })}
    </thead>
  );
};

export default EnhancedTableHeader;
