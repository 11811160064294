import * as yup from "yup";

// To check if the password meets certain criterias
// At least 1 small letter
// At least 1 capital letter
// At least a number
// At least any of these special characters
// Its length must be between 8 and 24 characters
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

export const registerSchema = yup
  .object({
    firstName: yup.string().min(4).max(50).required(),
    lastName: yup.string().min(4).max(50).required(),
    username: yup.string().min(4).max(12).required(),
    email: yup.string().email().required(),
    password: yup.string().min(8).max(50).required(),
    confirm_password: yup
      .string()
      .min(8)
      .max(50)
      .required()
      .test("", "Passwords did not match", function (value) {
        return this.parent.password === value;
      }),
  })
  .required();

export const rolesSchema = yup
  .object({ roles: yup.string().optional() })
  .required();

export const updateUserSchema = yup
  .object({
    firstName: yup.string().min(4).max(50).required(),
    lastName: yup.string().min(4).max(50).required(),
    username: yup.string().min(4).max(50).required(),
    email: yup.string().email().required(),
    // password: yup.string().optional(),
    enabled: yup.boolean(),
    // confirm_password: yup
    //   .string()
    //   .optional()
    //   .test("", "New passwords did not match", function (value) {
    //     return this.parent.password === value;
    //   }),
    // old_password: yup.string().optional(),
  })
  .required();
