import React, { useEffect, useState } from "react";
import FileDropzoneField from "../forms/fields/file.field";
import useFileMutation from "../../../common/data/hooks/useFileMutation";
import ButtonInputField from "../forms/fields/input.button";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../../common/forms/resolvers";
import FieldError from "../forms/fields/field.error";
import toast from "react-hot-toast";

const FileUploadManager = ({
  path,
  onNewFileDropped,
  onClientStartedUpload,
  onClientFinishedUpload,
  onUploadError,
  label,
  submitText,
  children,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const { data, loading, error, sendFiles } = useFileMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: formFieldResolvers.categoryFilesForm,
  });

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleUpload = ({ files }) => {
    setIsDragActive(false);
    sendFiles(path, files[0]);
  };

  useEffect(() => {
    setIsUploading(loading);
    if (data) {
      toast.success("File upload success");
      onClientFinishedUpload(data);
    }
    if (error) {
      toast.error(error?.response?.data?.errors[0]);
      console.log(error?.response?.data);
      onUploadError(error);
    }
  }, [data, error, loading, onUploadError, onClientFinishedUpload]);

  return (
    <div className="w-full h-full flex flex-col justify-items-center justify-start">
      <form
        className="w-full h-full flex flex-col"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleSubmit(handleUpload)}
        onSubmit={handleSubmit(handleUpload)}
        encType="multipart/form-data"
      >
        <FileDropzoneField
          isDragActive={isDragActive}
          register={() => register("files")}
          isUploading={isUploading}
        >
          {children}
        </FileDropzoneField>

        {label}

        <span className="mb-2"></span>
        <FieldError inputFieldError={errors.files} />

        <div className="w-fit h-fit py-1">
          <ButtonInputField
            type="submit"
            value={submitText ? submitText : "Save changes"}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default FileUploadManager;
