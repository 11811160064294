import React from "react";
import { IconBadge } from "./icon-badge";

export const CategorySectionCard = ({ children }) => {
  return <div className="p-2 rounded-md bg-white shadow-sm">{children}</div>;
};

const CategorySectionHeader = ({ icon, label, description }) => {
  return (
    <div className="flex flex-col justify-items-start justify-start">
      <div className="flex flex-row justify-items-start justify-start">
        <IconBadge icon={icon} />
        <span className="text-md pl-3 pt-2 font-sans text-lg">{label}</span>
      </div>
      {description && (
        <div className="px-2 text-gray-600 text-sm">{description}</div>
      )}
    </div>
  );
};

export const CategorySection = ({ icon, label, description, children }) => {
  return (
    <div className="mt-3 flex flex-col justify-items-center justify-between">
      <CategorySectionHeader
        icon={icon}
        label={label}
        description={description}
      />
      <div className="mt-2 p-2 rounded-md bg-white shadow-sm">{children}</div>
    </div>
  );
};
