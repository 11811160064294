export const toolbarConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "outdent",
      "indent",
      "bulletedList",
      "numberedList",
      "|",
      "link",
      "blockQuote",
      "insertTable",
      "insertImage",
      "mediaEmbed",
    ],
    shouldNotGroupWhenFull: true,
  },
};
