import HTMLReactParser from "html-react-parser/lib/index";
import React from "react";

const MoreText = ({ text, count, handleMore }) => {
  return text?.split(" ").length >= Number(count) ? (
    <span>
      {HTMLReactParser(
        text
          ?.split(" ")
          ?.filter((el, index) => index < Number(count))
          ?.join(" ")
      )}
      <span
        onClick={handleMore}
        className="text-green-400 underline rounded-sm w-fit p-0 ml-1 pl-0.5 hover:cursor-pointer"
      >
        read more...
      </span>
    </span>
  ) : (
    HTMLReactParser(text)
  );
};

export const MoreText2 = ({ text, count }) => {
  return text?.split(" ").length >= Number(count) ? (
    <span>
      {HTMLReactParser(
        text
          ?.split(" ")
          ?.filter((el, index) => index < Number(count))
          ?.join(" ")
      )}
    </span>
  ) : (
    HTMLReactParser(text)
  );
};

export default MoreText;
