import React from "react";

const ProgressLoader = ({ loading }) => {
  return loading ? (
    <div className="w-full">
      <div className="h-1 w-full bg-green-100 overflow-hidden">
        <div className="progress w-full h-full bg-green-700 left-right" />
      </div>
    </div>
  ) : null;
};

export const ProgressLoader2 = ({ loading, loadingText }) => {
  return loading ? (
    <div
      className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center"
      style={{ background: "rgba(0, 0, 0, 0.3)" }}
    >
      <div className="py-2 px-5 rounded-lg flex items-center flex-col">
        <div className="loader-dots block relative w-20 h-6 mt-2">
          <div className="absolute top-0 mt-1 w-4 h-4 rounded-full bg-green-800"></div>
          <div className="absolute top-0 mt-1 w-4 h-4 rounded-full bg-green-800"></div>
          <div className="absolute top-0 mt-1 w-4 h-4 rounded-full bg-green-800"></div>
          <div className="absolute top-0 mt-1 w-4 h-4 rounded-full bg-green-800"></div>
        </div>
        <div className="text-gray-500 text-xs font-light mt-2 text-center">
          {loadingText}...
        </div>
      </div>
    </div>
  ) : null;
};

export default ProgressLoader;
