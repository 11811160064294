/**
 * For each state, create a new key and value pair.
 * The key is used to identify @{this} state for that perticular object
 */
export const StorageKeys = {
  rootState: "appState",
  usersStateKey: "usersStateKey",
  categoriesState: "categoriesState",
  homeCategoriesState: "homeCategoriesState",
  cmsSelectedHomeCategoryStateKey: "cmsSelectedHomeCategoryStateKey",
  cmsSelectedFaqsCategoryStateKey: "cmsSelectedFaqsCategoryStateKey",
  cmsSelectedNewsCategoryStateKey: "cmsSelectedNewsCategoryStateKey",
  cmsSelectedLibraryCategoryStateKey: "cmsSelectedLibraryCategoryStateKey",
  cmsSelectedDirectoryCategoryStateKey: "cmsSelectedDirectoryCategoryStateKey",
  cmsSelectedGisCategoryStateKey: "cmsSelectedGisCategoryStateKey",
  cmsSelectedLicensingCategoryStateKey: "cmsSelectedLicensingCategoryStateKey",
  cmsSelectedFinancingCategoryStateKey: "cmsSelectedFinancingCategoryStateKey",
};

export const SORT_DIRECTION = {
  ASC: 0,
  DESC: 1,
};

export const CONTEXT_MENU_ACTION = {
  EDIT: "Edit",
  DETAILS: "Details",
  DELETE: "Delete",
  DISABLE: "Disable",
  ENABLE: "Enable",
};

export const CATEGORY_STATE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
  VIEW: "VIEW",
  LIST: "LIST",
};

export const CATEGORY_STATUS = {
  DRAFT: "isDraft",
  FOR_REVIEW: "isBeingReviewed",
  REVIEWED: "isReviewed",
  PUBLISHED: "isPublished",
  ARCHIVED: "isArchived",
};

export const USER_ROLE = {
  ADMIN: "ROLE_ADMIN",
  AUTHOR: "ROLE_AUTHOR",
  REVIEWER: "ROLE_EDITOR",
  PUBLISHER: "ROLE_PUBLISHER",
};

export const PAGE_MATCHED = {
  EDIT_SCREEN: "MATCH_EDIT_SCREEN",
  CREATE_NEW_SCREEN: "MATCH_CREATE_NEW_SCREEN",
  DETAILS_SCREEN: "MATCH_DETAILS_SCREEN",
  LANDING_SCREEN: "MATCH_LANDING",
};
