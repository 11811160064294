import React from "react";
import { TableHeader } from "./table.header";
import { algorithms } from "../../../common/data/algorithms";
import { CMSContextMenu } from "../menus/context.menu";
import MoreText from "../text.more";

export const TableStakeholder = ({
  stakeholders,
  tableColumns,
  menuItems,
  activeColumn,
  handleColumnSort,
  handleToggleActiveColumn,
  handleClick,
  onDelete,
  onView,
}) => {
  return (
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <TableHeader tableColumns={tableColumns} />
      <tbody>
        {stakeholders?.map((stakeholder) => (
          <tr
            className="bg-white even:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 hover:even:bg-gray-200 dark:hover:bg-gray-600 "
            key={stakeholder.name}
          >
            <td className="px-3 py-4">
              <div className="flex items-center">
                <img
                  src={stakeholder?.image}
                  onClick={() => handleClick(stakeholder)}
                  alt="avatar"
                  className="image-input-wrapper w-20 h-16 rounded-full cursor-pointer opacity-75-hover"
                />
              </div>
            </td>
            <th
              scope="row"
              className="flex items-center px-3 py-4 text-gray-900 whitespace-nowrap dark:text-white hover:cursor-pointer"
              onClick={() => handleClick(stakeholder)}
            >
              <div>
                <div className="text-base font-semibold hover:underline">
                  {algorithms?.firstLettersToUpperCase(stakeholder?.name)}
                </div>
                <div className="font-normal text-gray-500">
                  created by{" "}
                  <span className="text-sm text-gray-800">
                    {stakeholder.createdBy}
                  </span>{" "}
                  on {stakeholder.createdDate}
                </div>
              </div>
            </th>
            <td className="px-3 py-4 leading-6">
              <MoreText
                text={stakeholder?.about}
                count={50}
                handleMore={() => handleClick(stakeholder)}
              />
            </td>
            <td className="px-3 py-4">
              <CMSContextMenu
                items={menuItems}
                element={stakeholder?._id}
                onView={onView}
                onDelete={onDelete}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableStakeholder;
