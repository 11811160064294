import React, { lazy } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toolbarConfig } from "./editor.config";

const FieldError = lazy(() => import("../fields/field.error"));

const CMSEditor = ({
  label,
  name,
  inputFieldError,
  editorData,
  setEditorData,
  setValue,
  ...props
}) => {
  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col">
        <label className="text-sm font-semibold text-gray-600">{label}</label>
        <CKEditor
          {...props}
          editor={ClassicEditor}
          config={toolbarConfig}
          data={editorData}
          onChange={(event, editor) => {
            // console.log(Array.from(editor.ui.componentFactory.names()));
            setValue(name, editor.getData());
            setEditorData(editor.getData());
          }}
        />
        <FieldError inputFieldError={inputFieldError} />
        <span className="mb-2"></span>
      </div>
    </div>
  );
};

export default CMSEditor;
