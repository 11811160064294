import React, { Fragment } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { algorithms } from "../../../common/data/algorithms";
import CategoryApprovalStatus from "../category/category-approval.status";
import Button from "../buttons";
import { PencilIcon } from "@heroicons/react/24/outline";
import HTMLReactParser from "html-react-parser/lib/index";
import { InforType } from "../../../common/data/constants";

const CategoryInitInfor = ({ type, category, toggleEdit, approvable }) => {
  const authUser = useAuthUser();

  return (
    <Fragment>
      <div className="flex sm:flex-row flex-col justify-items-center justify-between">
        <span>
          <div className=" text-gray-600 flex flex-auto">
            <span className="font-bold text-xl">
              {algorithms.firstLettersToUpperCase(category.name)}{" "}
            </span>

            <CategoryApprovalStatus
              category={category}
              approvable={approvable}
            />
          </div>
          <div className="text-xs text-gray-600">
            created on{" "}
            <span className="font-bold text-gray-900">
              {algorithms.formatDate.toLocaleDateString(category.createdDate)}
            </span>{" "}
            by{" "}
            <span className="text-xs font-bold text-gray-900">
              {category.createdBy}
            </span>
          </div>
        </span>
        <span>
          <div className="text-xs sm:right-10 right-0 gap-1 text-gray-600">
            last update on{" "}
            <span className="font-bold text-gray-900">
              {algorithms.formatDate.toLocaleDateString(
                category.lastModifiedDate
              )}
            </span>{" "}
            by{" "}
            <span className="text-xs text-gray-900 font-bold">
              {category.lastModifiedBy}
            </span>
          </div>
          <span className="flex flex-row text-md right-10 gap-1 text-gray-600 justify-items-center justify-end">
            {authUser?.username === category?.createdBy && category?.isDraft ? (
              <Button onClick={toggleEdit} variant="ghost">
                <>
                  <PencilIcon className="h-4 w-4 mr-2" />
                  Edit category
                </>
              </Button>
            ) : (
              authUser?.username === category?.createdBy &&
              type === InforType.STAKEHOLDER && (
                <Button onClick={toggleEdit} variant="ghost">
                  <>
                    <PencilIcon className="h-4 w-4 mr-2" />
                    Edit stakeholder
                  </>
                </Button>
              )
            )}
          </span>
        </span>
      </div>
      <hr className="mt-1" />
      <div className="mt-2 text-gray-600 leading-8">
        {HTMLReactParser(category?.about)}
      </div>
    </Fragment>
  );
};

export default CategoryInitInfor;
