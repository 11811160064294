import { useCallback, useState } from "react";
import Config from "../../../common/data/api.service";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const useStakeholderMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const authUser = useAuthUser();

  const createStakeholder = useCallback(
    (urlPath, stakeholder) => {
      if (urlPath !== undefined && urlPath !== null) {
        setLoading(true);
        Config.PROD_REMOTE_API.post(urlPath, stakeholder, {
          headers: { Authorization: `Bearer ${authUser?.access_token}` },
        })
          .then((res) => res.data)
          .then((response) => {
            setData(response);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setError(error?.response?.data?.errors[0]);
          });
      }
    },
    [authUser?.access_token]
  );

  const updateStakeholder = useCallback(
    (stakeholder, values) => {
      if (stakeholder && values) {
        setLoading(true);
        Config.PROD_REMOTE_API.put(
          `stakeholders/${stakeholder}`,
          { contacts: { ...values } },
          { headers: { Authorization: `Bearer ${authUser?.access_token}` } }
        )
          .then((res) => res.data)
          .then((response) => {
            setData({ ...response, _id: stakeholder });
            setLoading(false);
          })
          .catch((error) => {
            setError(error?.response?.data?.errors[0]);
            throw new Error(error?.message);
          });
      }
    },
    [authUser?.access_token]
  );

  const deleteStakeholder = useCallback(
    (stakeholder) => {
      if (stakeholder) {
        setLoading(true);
        Config.PROD_REMOTE_API.delete(`stakeholders/${stakeholder}`, {
          headers: { Authorization: `Bearer ${authUser?.access_token}` },
        })
          .then((res) => res.data)
          .then((response) => {
            setData({ ...response, _id: stakeholder });
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setError(error?.response?.data?.errors[0]);
          });
      }
    },
    [authUser?.access_token]
  );

  return {
    loading,
    data,
    error,
    createStakeholder,
    deleteStakeholder,
    updateStakeholder,
  };
};

export default useStakeholderMutation;
