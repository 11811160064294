import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../schemas/login.schema";
import {
  registerSchema,
  rolesSchema,
  updateUserSchema,
} from "../schemas/register.schema";
import {
  newQuestionSchema,
  updateQuestionSchema,
} from "../schemas/faqs.schema";

import { shapeFileSchema } from "../schemas/shapefile.schema";
import {
  categoryFilesSchema,
  newCategorySchema,
  updateCategorySchema,
  visibilityForwardFormSchema,
  visibilityReverseFormSchema,
} from "../schemas/category.schema";
import {
  editStakeholderContactSchema,
  editStakeholderInforSchema,
  newStakeholdeImageSchema,
  newStakeholderSchema,
} from "../schemas/stakeholder.schema";
import { mapFilters } from "../schemas/filters.map.schema";
import { resetPasswordSchema } from "../schemas/reset.password.schema";

const formFieldResolvers = {
  loginForm: yupResolver(loginSchema),
  registerForm: yupResolver(registerSchema),
  rolesForm: yupResolver(rolesSchema),
  updateUserForm: yupResolver(updateUserSchema),
  newQuestionForm: yupResolver(newQuestionSchema),
  updateQuestionForm: yupResolver(updateQuestionSchema),
  newCategoryForm: yupResolver(newCategorySchema),
  updateCategorySchema: yupResolver(updateCategorySchema),
  categoryForwardVisibilityForm: yupResolver(visibilityForwardFormSchema),
  categoryReverseVisibilityForm: yupResolver(visibilityReverseFormSchema),
  categoryFilesForm: yupResolver(categoryFilesSchema),
  newStakeholderForm: yupResolver(newStakeholderSchema),
  newShapefileForm: yupResolver(shapeFileSchema),
  editStakeholderInforForm: yupResolver(editStakeholderInforSchema),
  editStakeholderContactForm: yupResolver(editStakeholderContactSchema),
  newStakeholdeImageForm: yupResolver(newStakeholdeImageSchema),
  mapFilters: yupResolver(mapFilters),
  resetPasswordSchema: yupResolver(resetPasswordSchema),
};

export default formFieldResolvers;
