import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { StorageKeys } from "../utils/constants";

/**
 * @author Augustine Simwela
 * configures a general purpose persitence for the app state.
 * Solves the problem of losing state during page reflesh
 */
const { persistAtom } = recoilPersist({
  // Key used to identify root state in local storage
  key: StorageKeys.rootState,
  // configurate which storage will be used to store the data, LocalStorage or SessionStorage
  storage: localStorage,
});

export const usersState = atom({
  key: StorageKeys.usersStateKey,
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const homeCategoriesState = atom({
  key: StorageKeys.homeCategoriesState,
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const categoriesState = atom({
  key: StorageKeys.categoriesState,
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedHomeCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedHomeCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedFaqsCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedFaqsCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedLibraryCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedLibraryCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedDirectoryCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedDirectoryCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedFinancingCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedFinancingCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedLicensingCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedLicensingCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedGisCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedGisCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cmsSelectedNewsCategoryStateKey = atom({
  key: StorageKeys.cmsSelectedNewsCategoryStateKey,
  default: {},
  effects_UNSTABLE: [persistAtom],
});
