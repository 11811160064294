import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { USER_ROLE } from "../../../common/utils/constants";
import { useState } from "react";
import { CATEGORY_TABS } from "../../../common/utils/category.constants";

const useActiveDefaultTab = () => {
  const [tabs, setTabs] = useState();
  const authUser = useAuthUser();
  const activeDefaultTab = () => {
    if (
      authUser?.roles[0] === USER_ROLE.AUTHOR ||
      authUser?.roles[0] === USER_ROLE.ADMIN
    ) {
      return CATEGORY_TABS[0];
    } else if (authUser?.roles[0] === USER_ROLE.REVIEWER) {
      return CATEGORY_TABS[1];
    } else if (authUser?.roles[0] === USER_ROLE.PUBLISHER) {
      return CATEGORY_TABS[2];
    }
  };

  return { tabs, setTabs, activeDefaultTab };
};

export default useActiveDefaultTab;
