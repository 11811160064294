import React, { Fragment } from "react";
import { PAGE_MATCHED } from "../../../common/utils/constants";

const PageToolbarSection = ({ children }) => <div className="">{children}</div>;

export const PageContentSection = ({ children }) => (
  <div className="flex flex-col mt-2">{children}</div>
);

const CategoryPageFactory = ({
  pageToolBarLanding: LandingPageToolBar,
  pageToolBarDetails: DetailsPageToolBar,
  pageToolBarNewCategory: NewCategoryPageToolBar,
  pageView,
  children,
}) => {
  switch (pageView) {
    case PAGE_MATCHED.DETAILS_SCREEN:
      return (
        <Fragment>
          <PageToolbarSection>
            <DetailsPageToolBar />
          </PageToolbarSection>
          {children}
        </Fragment>
      );
    case PAGE_MATCHED.CREATE_NEW_SCREEN:
      return (
        <Fragment>
          <PageToolbarSection>
            <NewCategoryPageToolBar />
          </PageToolbarSection>
          {children}
        </Fragment>
      );
    case PAGE_MATCHED.EDIT_SCREEN:
      return (
        <Fragment>
          <PageToolbarSection>
            <NewCategoryPageToolBar />
          </PageToolbarSection>
          {children}
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <PageToolbarSection>
            <LandingPageToolBar />
          </PageToolbarSection>
          {children}
        </Fragment>
      );
  }
};

export default CategoryPageFactory;
