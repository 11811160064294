import { LoaderIcon } from "react-hot-toast";

const ShapefilePicker = ({
  selectedFile,
  isUploading,
  handleFileSelectionChange,
  children,
}) => {
  return (
    <div className="w-full h-full flex flex-col justify-items-center justify-start">
      <div
        className={[
          `flex justify-center items-center w-full h-48 border-2 border-dashed rounded-lg p-5 border-gray-300 cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-60`,
          isUploading ? "bg-red-100 hover:bg-red-200" : "",
        ].join(" ")}
      >
        <label
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center"
        >
          {isUploading && <LoaderIcon className="w-8 h-8 animate-spin" />}
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-2 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>

            <span>
              <div className="flex flex-col items-center">
                <input
                  type="file"
                  id="custom-input"
                  accept=".gpkg"
                  onChange={handleFileSelectionChange}
                  hidden
                />
                <label
                  htmlFor="custom-input"
                  className="block text-sm text-slate-500 mr-4 py-2 px-4
                            rounded-md border-0  font-semibold bg-pink-50
                        hover:bg-pink-100 cursor-pointer"
                >
                  <span className="font-semibold">Click to upload</span>
                </label>
                <label className="text-sm text-slate-500">
                  {selectedFile?.name}
                </label>
              </div>
            </span>

            {children}
          </div>
        </label>
      </div>
    </div>
  );
};

export default ShapefilePicker;
