import React, { createContext, useContext, useState } from "react";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useNavigate } from "react-router-dom";

const AuthRoleContext = createContext();

const AuthRoleProvider = ({ children }) => {
  const [auth, setAuth] = useState();
  const [allowedRoles, setAllowedRoles] = useState([]);
  const navigate = useNavigate();
  const isAuth = useIsAuthenticated();

  if (!isAuth) {
    return navigate("/login");
  }

  return (
    <AuthRoleContext.Provider
      value={{ auth, setAuth, allowedRoles, setAllowedRoles }}
    >
      {children}
    </AuthRoleContext.Provider>
  );
};

const useAuthRole = () => {
  return useContext(AuthRoleContext);
};

export { AuthRoleContext, AuthRoleProvider, useAuthRole };
