import React from "react";
import { algorithms } from "../../../../common/data/algorithms";

const FieldError = ({ inputFieldError }) => {
  return inputFieldError ? (
    <span className="text-xs text-red-500">
      {algorithms?.firstLetterOnlyToUpperCase(inputFieldError?.message)}
    </span>
  ) : (
    <React.Fragment />
  );
};

export default FieldError;
