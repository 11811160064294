export const Tab = ({ label, id, name, selected = false, onSelected }) => {
  return (
    <button
      id={id}
      name={name}
      className={[
        `flex flex-row pl-0.5 px-1.5 mx-0.5`,
        selected
          ? "shadow-md bg-green-700 font-semibold text-white hover:text-white"
          : " ",
        `rounded-md text-gray-600 hover:text-gray-800 items-center justify-between`,
      ].join(" ")}
      onClick={(e) => onSelected(e.currentTarget.id)}
    >
      <span>{label}</span>
    </button>
  );
};

export const Tabs = ({ children }) => {
  return (
    <div className="flex flex-row w-auto px-0.5 py-1 mr-2 rounded-lg bg-gray-100">
      {children}
    </div>
  );
};
