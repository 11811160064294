import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  CONTEXT_MENU_ACTION,
  USER_ROLE,
} from "../../../common/utils/constants";

const detailsMenu = [
  {
    label: CONTEXT_MENU_ACTION.DETAILS,
    icon: <EyeIcon />,
    roles: [
      // roles which can perform VIEWDETAILS action on a category
      USER_ROLE.AUTHOR,
      USER_ROLE.REVIEWER,
      USER_ROLE.PUBLISHER,
      USER_ROLE.ADMIN,
    ],
  },
];

export const menuItems = [
  ...detailsMenu,
  {
    label: CONTEXT_MENU_ACTION.DELETE,
    icon: <TrashIcon />,
    roles: [USER_ROLE.AUTHOR], // roles which can perform DELETE action on a category
  },
];

export const userListMenuItems = [
  {
    label: CONTEXT_MENU_ACTION.EDIT,
    icon: <PencilIcon />,
    roles: [USER_ROLE.AUTHOR], // roles which can perform EDIT action on a category
  },
  {
    label: CONTEXT_MENU_ACTION.DELETE,
    icon: <TrashIcon />,
    roles: [USER_ROLE.AUTHOR], // roles which can perform DELETE action on a category
  },
];
