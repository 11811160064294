import React from "react";
import PropTypes from "prop-types";

export const ConfirmActionManager = ({
  alertMessage,
  cancelButtonText,
  confirmButtonText,
  isAlertOpen = false,
  icon,
  handleAlertCancel,
  handleAlertConfirm,
  loading = false,
}) =>
  isAlertOpen ? (
    <div
      className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center"
      style={{ background: "rgba(0, 0, 0, 0.3)" }}
    >
      <div className="opacity-25 w-full h-full absolute z-10 inset-0"></div>
      <div className="bg-white border rounded-md md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative shadow-lg">
        <div className="md:flex items-center">
          <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
            <svg
              className="w-20 h-20 text-red-600 text-3xl mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
            <p className="font-bold text-2xl text-red-600">Warning!</p>
            <p className="text-sm text-gray-700 mt-1 font-light">
              {alertMessage
                ? alertMessage
                : "You will lose data if you continue to delete. This action cannot be undone."}
            </p>
          </div>
        </div>
        <div className="text-center md:text-right mt-4 md:flex md:justify-end">
          <button
            onClick={handleAlertCancel}
            className="text-gray-900 bg-white hover:bg-gray-100 focus:ring-2 focus:ring-cyan-200 border border-gray-200 font-semibold inline-flex items-center rounded-md text-base px-3 py-2 text-center mr-2"
          >
            No, cancel
          </button>
          <button
            onClick={handleAlertConfirm}
            className="text-white bg-red-600 hover:bg-red-800 focus:ring-2 focus:ring-red-300 font-semibold rounded-md text-base inline-flex items-center px-3 py-2 text-center"
          >
            Yes, I'm sure
          </button>
        </div>
      </div>
    </div>
  ) : null;

ConfirmActionManager.propTypes = {
  // cancelButtonText: PropTypes.string.isRequired,
  // confirmButtonText: PropTypes.string.isRequired,
  isAlertOpen: PropTypes.bool.isRequired,
  alertMessage: PropTypes.string,
  // icon: PropTypes.string,
  handleAlertConfirm: PropTypes.func.isRequired,
  handleAlertCancel: PropTypes.func.isRequired,
};

export default ConfirmActionManager;
