import React, { useContext, useEffect, useState } from "react";
import CMSEditor from "../forms/editor/editor";
import FormSection from "../forms/sections";
import TextInputField, { RadioInputField } from "../forms/fields/input.field";
import ButtonInputField from "../forms/fields/input.button";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../../common/forms/resolvers";
import {
  ActiveRouteContext,
  PageResourceLoaderContext,
} from "../../../routes/active.route.context";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useCategoryMutation from "../../../common/data/hooks/useCategoryMutation";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { categoriesState } from "../../../common/state/state";

const CMSCreateCategory = ({ formDescription, submitButtonLabel }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [editorData, setEditorData] = useState("");
  const { activeRoute: category } = useContext(ActiveRouteContext);
  const { setLoading } = useContext(PageResourceLoaderContext);

  const { data, loading, error, createCategory } = useCategoryMutation();
  const categories = useRecoilValue(categoriesState);
  const setCmsCategories = useSetRecoilState(categoriesState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: formFieldResolvers.newCategoryForm,
  });

  const handleFormSubmit = (values) =>
    createCategory(
      category?._id ? `categories/${category?._id}` : `categories`,
      values
    );

  useEffect(() => {
    if (error) {
      toast.error(error);
      console.log(error);
    }
  }, [error]);

  useEffect(() => {
    setLoading(loading);
    if (data && !loading) {
      // if (!categories) {
      //   setCmsCategories([...categories, data]);
      // }

      toast.success("Created successfully");
      navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}`);
    }
  }, [data, loading, navigate, pathname, setLoading]);

  return (
    <div className="flex flex-col min-w-full min-h-full bg-white p-2 rounded-sm shadow-sm">
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex flex-col w-full"
      >
        <TextInputField
          register={() => register("name")}
          type="text"
          label="Name"
          placeholder="Enter name..."
          ariaPlaceholder="Enter name"
          inputFieldError={errors.name}
        />
        <TextInputField
          register={() => register("shortName")}
          type="text"
          label="ShortName"
          placeholder="Enter shortname..."
          ariaPlaceholder="Enter shortname"
          inputFieldError={errors?.shortName}
        />

        <RadioInputField
          register={() => register("hasDocuments")}
          label="Are you going to attach any documents? (optional)"
          isVisible={true}
          className="py-2 my-1"
        />

        <CMSEditor
          label="About"
          name="about"
          setValue={setValue}
          editorData={editorData}
          setEditorData={setEditorData}
          inputFieldError={errors.about}
        />

        <FormSection className="py-1.5 flex-col" show={true}>
          <div className="flex flex-row">
            <ButtonInputField type="submit" value={submitButtonLabel} />
          </div>
        </FormSection>
      </form>
    </div>
  );
};

export default CMSCreateCategory;
