import { useState, useEffect, useCallback } from "react";
import Config from "../api.service";
import { algorithms } from "../algorithms";

const URL_PATH_SEGMENTS = [
  {
    name: "marep-centers",
    type: "ELECTRIFIED",
  },
  {
    name: "marep-centers",
    type: "TO_BE_ELECTRIFIED",
  },
  {
    name: "distribution-lines",
    type: "ELEVEN_KV_LINES",
  },
  {
    name: "distribution-lines",
    type: "FOUR_HUNDRED_KV_LINES",
  },
  {
    name: "distribution-lines",
    type: "THIRTY_THREE_KV_LINES",
  },
  {
    name: "minigrids",
    type: "EXISTING",
  },
  {
    name: "minigrids",
    type: "POTENTIAL",
  },
  {
    name: "transformers",
    type: "GROUND",
  },
  {
    name: "transformers",
    type: "OVERHEAD",
  },
  {
    name: "substations",
    type: undefined,
  },
];

const useGisShapeQuery = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getDistrictFeature = useCallback(async (district, pathnames) => {
    if (pathnames?.length > 0 && district) {
      setLoading(true);
      const promises = pathnames?.map((path) => {
        const pathSegment = URL_PATH_SEGMENTS.filter(
          (object) => object.type === algorithms?.toUpperCase(path)
        )[0];

        let URL_PATH = "";
        if (pathSegment?.type) {
          URL_PATH = `districts/${district?._id}/${pathSegment.name}?type=${pathSegment.type}`;
        } else {
          URL_PATH = `districts/${district?._id}/substations`;
        }

        return Config.PROD_REMOTE_API.get(URL_PATH);
      });
      const allSettled = Promise.allSettled(promises);
      allSettled
        .then((response) => {
          setLoading(false);
          setData(
            [...response?.values()]
              .map(({ status, reason, value }) => {
                if (status === "fulfilled") {
                  return value?.data;
                }
                if (status === "rejected") {
                  setError({ message: reason?.response?.data?.errors[0] });
                  return undefined;
                }
                return undefined;
              })
              .filter((value) => value !== undefined)
          );
        })
        .catch((error) => {
          setLoading(false);
          setError(error?.response?.data?.errors[0]);
        });
    }
  }, []);

  const refetch = useCallback(async (path) => {
    if (path !== undefined && path !== null) {
      setLoading(true);
      const data = await Config.PROD_REMOTE_API.get(path)
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          return data;
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setError((error) => error?.message);
        });
      setData(data);
    }
  }, []);

  useEffect(() => {
    refetch(url);
  }, [url, refetch, setLoading, setError, setData]);

  return { loading, data, error, refetch, getDistrictFeature };
};

export default useGisShapeQuery;
