import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../common/forms/resolvers";
import toast from "react-hot-toast";
import CMSEditor from "../common/forms/editor/editor";
import FormSection from "../common/forms/sections";
import ButtonInputField from "../common/forms/fields/input.button";
import useStakeholderMutation from "../../common/data/hooks/useStakeholderMutation";
import TextInputField from "../common/forms/fields/input.field";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CategorySection } from "../common/page.section";

const CMSCreateStakeholder = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [editorData, setEditorData] = useState("");
  const [editorVisionData, setVisionEditorData] = useState("");

  const { data, loading, error, createStakeholder } = useStakeholderMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: formFieldResolvers.newStakeholderForm,
  });

  const handleFormSubmit = ({ name, about, vision, ...contacts }) => {
    createStakeholder(`stakeholders`, {
      name,
      about,
      vision,
      contacts,
    });
  };

  useEffect(() => {
    if (data) {
      toast.success("Stakeholder successfully created");
      navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}`, {
        state: data,
      });
    }

    if (error) {
      toast.error(error);
      console.log(error);
    }
  }, [data, loading, error, navigate, pathname]);

  return (
    <div className="flex flex-col min-w-full min-h-full px-2">
      <CategorySection
        label="Details"
        description="Stakeholder's details"
        icon={InformationCircleIcon}
      >
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col"
        >
          <TextInputField
            register={() => register("name")}
            type="text"
            label="Name (legal name)"
            placeholder="Enter name..."
            ariaPlaceholder="Enter name"
            inputFieldError={errors?.name}
          />

          <TextInputField
            register={() => register("address")}
            type="text"
            label="Physical address"
            placeholder="Enter physical address..."
            ariaPlaceholder="Enter physical address"
            inputFieldError={errors?.address}
          />

          <TextInputField
            register={() => register("email")}
            type="email"
            label="Email"
            placeholder="Enter email address..."
            ariaPlaceholder="Enter email address"
            inputFieldError={errors?.email}
          />

          <TextInputField
            register={() => register("telephone")}
            type="tel"
            label="Telephone"
            placeholder="Enter telephone..."
            ariaPlaceholder="Enter telephone"
            inputFieldError={errors?.telephone}
          />

          <TextInputField
            register={() => register("website")}
            type="url"
            label="Website"
            placeholder="Enter website..."
            ariaPlaceholder="Enter website"
            inputFieldError={errors?.website}
          />

          <CMSEditor
            label="About"
            name="about"
            setValue={setValue}
            editorData={editorData}
            setEditorData={setEditorData}
            inputFieldError={errors?.about}
          />

          <CMSEditor
            label="Vision"
            name="vision"
            setValue={setValue}
            editorData={editorVisionData}
            setEditorData={setVisionEditorData}
            inputFieldError={errors?.vision}
          />

          <FormSection className="py-1.5 flex-col" show={true}>
            <div className="flex flex-row">
              <ButtonInputField type="submit" value="Create new stakeholder" />
            </div>
          </FormSection>
        </form>
      </CategorySection>
    </div>
  );
};

export default CMSCreateStakeholder;
