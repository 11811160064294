import React, { Fragment, lazy } from "react";
import Checkbox from "../../buttons/checkbox";

const FieldError = lazy(() => import("./field.error"));

export const RadioInputField = ({
  label,
  className,
  register,
  inputFieldError,
  isVisible,
  ...props
}) => {
  return (
    <Fragment>
      <Checkbox
        {...props}
        {...register()}
        className={className}
        label={label}
        isVisible={isVisible}
      />
      <FieldError inputFieldError={inputFieldError} />
      <span className="mb-2"></span>
    </Fragment>
  );
}; 

const TextInputField = ({
  type,
  label,
  value,
  disabled = false,
  className,
  placeholder,
  ariaPlaceholder,
  autoComplete,
  register,
  inputFieldError,
  ...props
}) => {
  return (
    <Fragment>
      <label className="text-sm font-semibold text-gray-600">{label}</label>
      <input
        {...props}
        {...register()}
        value={value}
        disabled={disabled}
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        aria-placeholder={ariaPlaceholder}
        className={[
          "max-sm:text-sm block w-full max-sm:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6",
          disabled ? " bg-gray-200 cursor-not-allowed " : "",
          inputFieldError
            ? "ring-red-300 focus:ring-red-400"
            : "ring-gray-200 focus:ring-indigo-300",
        ].join(" ")}
      />
      <FieldError inputFieldError={inputFieldError} />
      <span className="mb-2"></span>
    </Fragment>
  );
};

export default TextInputField;
