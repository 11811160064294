import React from "react";
import TextInputField from "./fields/input.field";
import ButtonInputField from "./fields/input.button";
import CMSEditor from "./editor/editor";

const InforTypeForm = ({
  register,
  errors,
  isValid,
  isSubmitting,
  setValue,
  editorData,
  setEditorData,
  handleSubmit,
  handleFormSubmit,
  children,
}) => {
  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex flex-col justify-items-start justify-start"
    >
      <TextInputField
        register={() => register("name")}
        type="text"
        label="Name *"
        placeholder="Enter name..."
        ariaPlaceholder="Enter name"
        inputFieldError={errors.name}
      />

      {children}

      <CMSEditor
        label="About *"
        name="about"
        setValue={setValue}
        editorData={editorData}
        setEditorData={setEditorData}
        inputFieldError={errors?.about}
      />

      <div className="w-fit h-fit py-1">
        <ButtonInputField
          type="submit"
          disabled={!isValid || isSubmitting}
          value="Save changes"
        />
      </div>
    </form>
  );
};

export default InforTypeForm;
