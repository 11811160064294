import React, { lazy } from "react";
import { algorithms } from "../../../../common/data/algorithms";

const FieldError = lazy(() => import("./field.error"));

const RadioInputField = ({
  type,
  label,
  disabled = false,
  className,
  placeholder,
  ariaPlaceholder,
  register,
  checked,
  setValue,
  inputFieldError,
  children,
}) => {
  return (
    <div className="flex flex-col items-start justify-start">
      <div className="flex flex-row py-1.5 pt-4 items-start">
        <input
          {...register()}
          disabled={disabled}
          onChange={(e) => setValue(e.target.name, e.target.value)}
          type={type}
          value={label}
          checked={checked}
          placeholder={placeholder}
          aria-placeholder={ariaPlaceholder}
          className={[
            "border-0 bg-gray-200 checked:bg-green-600 hover:bg-gray-300 sm:text-sm sm:leading-6",
            disabled
              ? " bg-gray-300 hover:bg-gray-300 cursor-not-allowed "
              : "",
          ].join(" ")}
        />
        <label className="text-sm pl-5 font-semibold text-gray-700">
          {algorithms.splitAndFirstLetterToUpperCase(label, "_")}
        </label>
      </div>
      <FieldError inputFieldError={inputFieldError} />
      {children}
      <span className="mb-1"></span>
    </div>
  );
};

export default RadioInputField;
