import { useCallback, useEffect, useState } from "react";
import Config from "../../../common/data/api.service";
import { algorithms } from "../../../common/data/algorithms";

const useQueryCategory = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const refetch = useCallback(async (path) => {
    if (path !== undefined && path !== null) {
      setLoading(true);
      await Config.PROD_REMOTE_API.get(path)
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          const categories = data?.subCategories?._embedded?.categories?.map(
            (subCat) => {
              return {
                ...subCat,
                createdDate: algorithms.formatDate.toLocaleDateString(
                  subCat.createdDate
                ),
              };
            }
          );
          // then sort categories by sortOrder property
          setError(null);
          setData(
            algorithms?.stableSort(
              categories,
              (first, curr) => -algorithms?.desc(first, curr, "sortOrder")
            )
          );
        })
        .catch((err) => {
          console.log(err?.response?.data?.errors[0]);
          setLoading(false);
          setError(err?.response?.data?.errors[0]);
          setData(null);
        });
    }
  }, []);

  useEffect(() => {
    refetch(url);
  }, [url, refetch, setLoading, setError, setData]);

  return { loading, data, error, refetch };
};

export default useQueryCategory;
