import React from "react";

const FormSection = ({ title, subTitle, className, show, children }) => {
  return (
    show && (
      <div
        className={`flex flex-row ${
          className ? className : "pb-3"
        } justify-between`}
      >
        <div className="flex flex-col w-1/4 items-start justify-start">
          <div className="text-xl text-md text-gray-600">{title}</div>
          <div className="text-sm text-gray-500">{subTitle}</div>
        </div>
        <div className="flex flex-col w-3/4 items-start justify-start">
          {children}
        </div>
      </div>
    )
  );
};

export default FormSection;
