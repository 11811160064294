import { useCallback, useEffect, useState } from "react";

const useMatchedPage = (location, pathname) => {
  const [matched, setMatchLocation] = useState();
  const pageMatched = useCallback(
    (loc) => setMatchLocation(loc),
    [setMatchLocation]
  );

  useEffect(() => setMatchLocation(location), [location]);

  switch (matched) {
    case "details":
      return ["MATCH_DETAILS_SCREEN", pageMatched];

    case `new-${pathname}`:
      return ["MATCH_CREATE_NEW_SCREEN", pageMatched];

    case "edit":
      return ["MATCH_EDIT_SCREEN", pageMatched];

    //default match landing
    default:
      return ["MATCH_LANDING", pageMatched];
  }
};

export default useMatchedPage;
