import React, { Fragment, useEffect, useState } from "react";
import Button from "../buttons/index.js";
import { useForm } from "react-hook-form";
import formFieldResolvers from "../../../common/forms/resolvers/index.js";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CMSEditor from "./editor/editor.js";
import TextInputField, { RadioInputField } from "./fields/input.field.js";
import toast from "react-hot-toast";
import Config from "../../../common/data/api.service.js";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { InforType } from "../../../common/data/constants.js";
import InforTypeForm from "./category-infor.type.js";
import CategoryInitInfor from "./category-infor.init.js";

const CategoryInforForm = ({
  category,
  setUpdatedCategory,
  approvable,
  type,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleEdit = () => setIsEditing((current) => !current);
  const [editorData, setEditorData] = useState("");
  const authUser = useAuthUser();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    resolver: type === InforType.CATEGORY
      ? formFieldResolvers.newCategoryForm
      : formFieldResolvers.editStakeholderInforForm,
  });

  const handleFormSubmit = async (values) => {
    try {
      const { data } = await Config.PROD_REMOTE_API.put(
        type === InforType.CATEGORY
          ? `categories/${category?._id}`
          : `stakeholders/${category?._id}`,
        { ...values },
        { headers: { Authorization: `Bearer ${authUser?.access_token}` } }
      );
      setUpdatedCategory(data);
      setIsEditing(false);
      toast.success("Update success");
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]);
      console.log(error?.response?.data);
      throw new Error(error?.message);
    }
  };

  useEffect(() => {
    const stakeholderType = ["vision"];
    const categoryType = ["shortName", "hasDocuments"];
    const values =
      type === InforType.CATEGORY
        ? categoryType
        : stakeholderType; 
        
    ["name", "about", ...values].map((prop) => {
      setValue(prop, category[prop]);
      if (prop === "about") {
        setEditorData(category?.about);
      } else if (prop === "vision") {
        setEditorData(category?.vision);
      }
      return () => null;
    });
  }, [category, type, setValue]);

  return (
    <Fragment>
      {isEditing ? (
        <div className="flex flex-col">
          <div className="mb-2 font-medium flex items-center justify-between">
            Editing details
            <Button onClick={toggleEdit} variant="ghost">
              <XMarkIcon className="h-4 w-4 mr-2" />
              Cancel editing
            </Button>
          </div>
          {isEditing && (
            <InforTypeForm
              register={register}
              isSubmitting={isSubmitting}
              isValid={isValid}
              errors={errors}
              handleSubmit={handleSubmit}
              handleFormSubmit={handleFormSubmit}
              setValue={setValue}
              editorData={editorData}
              setEditorData={setEditorData}
            >
              {type === InforType.CATEGORY && (
                <Fragment>
                  <TextInputField
                    register={() => register("shortName")}
                    type="text"
                    label="ShortName *"
                    placeholder="Enter shortname..."
                    ariaPlaceholder="Enter shortname"
                    inputFieldError={errors?.shortName}
                  />

                  <RadioInputField
                    register={() => register("hasDocuments")}
                    label="Has documents or attachments?"
                    isVisible={true}
                    className="py-2 my-1"
                  />
                </Fragment>
              )}

              {type === InforType.STAKEHOLDER && (
                <CMSEditor
                  label="Vision *"
                  name="vision"
                  setValue={setValue}
                  editorData={editorData}
                  setEditorData={setEditorData}
                  inputFieldError={errors?.about}
                />
              )}
            </InforTypeForm>
          )}
        </div>
      ) : (
        category && (
          <CategoryInitInfor
            category={category}
            toggleEdit={toggleEdit}
            approvable={approvable}
            type={type}
          />
        )
      )}
    </Fragment>
  );
};

export default CategoryInforForm;
