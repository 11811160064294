import React, { useEffect, useState } from "react";
import CategoryPageFactory, {
  PageContentSection,
} from "../common/category/category.page.factory";
import PageToolBar from "../common/toolbars/page.toolbar";
import { Button } from "../common/forms/fields/input.button";
import useQueryCategory from "../../common/data/hooks/useQueryCategory";
import useActiveDefaultTab from "../common/category/useActiveDefaultTab";
import useMatchedPage from "../common/category/useMatchingPage";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { algorithms } from "../../common/data/algorithms";
import { categoriesState } from "../../common/state/state";
import toast from "react-hot-toast";
import useCategoryMutation from "../../common/data/hooks/useCategoryMutation";
import { useSetRecoilState } from "recoil";
import { PAGE_MATCHED, USER_ROLE } from "../../common/utils/constants";
import { ArrowLongLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import AdminPanel from "./cms.gis.listing";
import CMSGisListing from "./cms.gis.listing";
import useActiveGisTabs from "./useGisTabs";

const CMSGisPage = ({
  pathname,
  pageToolBarLandingTitle,
  pageToolBarLandingSubTitle,
  pageToolBarLandingLabel,
  pageToolBarDetailsTitle = null,
  pageToolBarDetailsSubTitle,
  pageToolBarNewCategoryTitle = null,
  pageToolBarNewCategorySubTitle,
  recoilCategoryStateKey,
  searchPlaceholder,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const categoryPage = paths[2]; // get third pathname for the active category

  const matchLanding = useMatch(`${location.pathname}`);
  const [matchedPage] = useMatchedPage(paths[paths.length - 1], pathname);

  const authUser = useAuthUser();
  const { tabs, setTabs, activeDefaultTab } = useActiveGisTabs();
  const [selected, setSelected] = useState(activeDefaultTab());
  const [selectedCategoryToView, setSelectedCategoryToView] = useState({});

  // const [isOverlayOpen, setOverlayOpen] = useState(false);
  // const toggleOverlay = () => setOverlayOpen((open) => !open);

  // if home page, fetch all categories w/out filtering by status
  // these subcategories are already PUBLISHED
  // const categoryStatusPath =
  //   categoryPage !== "home" ? `&status=${selected?.status}` : "";

  const setCmsCategories = useSetRecoilState(categoriesState);

  useEffect(() => {}, [selectedCategoryToView]);

  return (
    <div className="flex flex-col justify-center">
      <CategoryPageFactory
        pageView={matchedPage}
        pageToolBarLanding={() => (
          <PageToolBar
            title={pageToolBarLandingTitle}
            subTitle={pageToolBarLandingSubTitle}
          >
            {authUser?.roles.includes(USER_ROLE.ADMIN) && (
              <Button
                handleOnClick={() => navigate(`new-${pathname}`)}
                label={pageToolBarLandingLabel}
                className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
              >
                <PlusIcon className="w-6 h-6 pr-1" />
              </Button>
            )}
          </PageToolBar>
        )}
        pageToolBarDetails={() => (
          <PageToolBar
            title={
              <Button
                handleOnClick={() => navigate(-1)}
                label="Go Back"
                className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
              >
                <ArrowLongLeftIcon className="w-6 h-6 pr-1" />
              </Button>
            }
            subTitle={pageToolBarDetailsSubTitle}
          />
        )}
        pageToolBarNewCategory={() => (
          <PageToolBar
            title={
              <Button
                handleOnClick={() => navigate(-1)}
                label="Go Back"
                className="flex flex-row text-gray-700 bg-white hover:text-black hover:bg-gray-300 uppercase shadow-xs font-semibold rounded-md shadow-xs"
              >
                <ArrowLongLeftIcon className="w-6 h-6 pr-1" />
              </Button>
            }
            subTitle={pageToolBarNewCategorySubTitle}
          />
        )}
      >
        <PageContentSection>
          {Boolean(matchLanding) &&
          matchedPage === PAGE_MATCHED.LANDING_SCREEN ? (
            <div className="flex flex-row w-full h-full justify-between">
              <div
                className={[
                  `p-2 h-full rounded-md bg-white shadow-sm`,
                  "w-full",
                  // isOverlayOpen ? `w-[80%]` : `w-[100%]`,
                ].join(" ")}
              >
                <CMSGisListing
                  loading={true}
                  authUser={authUser}
                  tabs={tabs}
                  setTabs={setTabs}
                  selected={selected?.name}
                  setSelected={setSelected}
                  setSelectedCategoryToView={setSelectedCategoryToView}
                  refetch={() => {}}
                  categoryPage={categoryPage}
                  recoilCategoryStateKey={recoilCategoryStateKey}
                  searchPlaceholder={searchPlaceholder}
                  status={selected?.status}
                  onDeleteCategory={() => {}}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col w-full h-full">{children}</div>
          )}
        </PageContentSection>
      </CategoryPageFactory>
    </div>
  );
};

export default CMSGisPage;
