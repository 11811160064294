import * as yup from "yup";

export const newCategorySchema = yup
  .object({
    name: yup.string().required(),
    shortName: yup.string().required(),
    about: yup.string().required(),
    hasDocuments: yup.boolean().required(),
  })
  .required();

export const updateCategorySchema = yup
  .object({
    name: yup.string(),
    shortName: yup.string(),
    about: yup.string(),
    hasDocuments: yup.boolean(),
  })
  .required();

export const visibilityForwardFormSchema = yup.object({
  isDraft: yup.boolean(),
  isPublished: yup.boolean(),
  isBeingReviewed: yup.boolean(),
  isReviewed: yup.boolean(),
});

export const visibilityReverseFormSchema = yup.object({
  isDraft: yup.boolean(),
  isPublished: yup.boolean(),
  isBeingReviewed: yup.boolean(),
  isReviewed: yup.boolean(),
  reviewComment: yup.string().required(),
});

export const categoryFilesSchema = yup.object({
  // files: yup.object().required(),
});
