import React, { Fragment, useCallback, useEffect, useState } from "react";
import { algorithms } from "../../common/data/algorithms";
import { SORT_DIRECTION, USER_ROLE } from "../../common/utils/constants";
import { Tab, Tabs } from "../common/status.tabs";
import ToolBar from "../common/tables/toolbar";
import ConfirmActionManager from "../common/alerts/alert.confirmation";
import { GIS_TABS } from "../../common/utils/gis.tabs";
import CMSMapContent from "./gis.map.content";
import useQueryRegionsAndDistricts from "../../common/data/hooks/useQueryRegionsAndDistricts";
import { ENERGY_RESOURCE_TYPES } from "../../common/utils/energy.resource.types";
import useGisShapeQuery from "../../common/data/hooks/useShapeQuery";
import toast from "react-hot-toast";

const CMSGisListing = ({ tabs, setTabs, selected, setSelected, authUser }) => {
  const [districts, setDistricts] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedEnergyResource, setSelectedEnergyResource] = useState("");
  const [
    selectedDistrictForEnergyResource,
    setSelectedDistrictForEnergyResource,
  ] = useState();
  const {
    data,
    error: loadinRegionsAndDistrictsError,
    loading: loadinRegionsAndDistricts,
  } = useQueryRegionsAndDistricts("/regions");

  const {
    data: districtShape,
    refetch: refetchDistrict,
    loading: districtShapeLoading,
    error: loadingDistrictShapeError,
    // ...districtProps
  } = useGisShapeQuery();
  const {
    data: districtFeature,
    refetch: getDistrictFeature,
    loading: districtFeatureLoading,
    error: loadingDistrictFeatureError,
    // ...districtFeatureProps
  } = useGisShapeQuery();
  const {
    data: regionShape,
    refetch: refetchRegion,
    loading: regionShapeLoading,
    error: loadingRegionShapeError,
    // ...regionProps
  } = useGisShapeQuery();

  const handleSelectedTab = (activeTab) => {
    setSelected(GIS_TABS?.filter((tab) => tab?.name === activeTab)[0]);
  };

  const handleGisSelectionChange = (e) => {
    switch (selected) {
      case "region":
        const region = regions.find(
          (region) => region.name.toLowerCase() === e.target.value.toLowerCase()
        );

        refetchRegion(`/regions/${region._id}`);
        break;
      case "district":
        const district = districts.find(
          (district) =>
            district.properties.name.toLowerCase() ===
            e.target.value.toLowerCase()
        );

        refetchDistrict(`/districts/${district._id}`);
        break;
      case "energy":
        const districtForEnegryResource = districts.find(
          (district) =>
            district.properties.name.toLowerCase() ===
            selectedDistrictForEnergyResource.toLowerCase()
        );
        const enrgyResource = ENERGY_RESOURCE_TYPES.find(
          (resource) =>
            resource.name.toLowerCase() === e.target.value.toLowerCase()
        );
        getDistrictFeature(
          `/districts/${districtForEnegryResource._id}/${enrgyResource?.path}`
        );
        setSelectedEnergyResource(enrgyResource);
        break;
    }
  };

  const handleSelctedDistrictFOrEnrgyResource = (e) => {
    const targetDitrict = e.target.value;
    setSelectedDistrictForEnergyResource(targetDitrict);
    const districtId = districts.find(
      (district) =>
        district?.properties?.name.toLowerCase() === targetDitrict.toLowerCase()
    )?._id;
    refetchDistrict(`/districts/${districtId}`);
    if (selectedEnergyResource) {
      getDistrictFeature(
        `/districts/${districtId}/${selectedEnergyResource?.path}`
      );
    }
  };

  useEffect(() => {
    if (loadinRegionsAndDistrictsError) {
      toast.error("Failed to fetch regions and Districts");
    }
    if (loadingDistrictFeatureError) {
      toast.error("Failed to fetch district features");
    }

    if (loadingRegionShapeError) {
      toast.error("Failed to fetch region boundary");
    }

    if (loadingDistrictShapeError) {
      toast.error("Failed to fetch district boundary");
    }
  }, [
    loadinRegionsAndDistrictsError,
    loadingDistrictFeatureError,
    loadingRegionShapeError,
    loadingDistrictShapeError,
  ]);

  useEffect(() => {
    setTabs(
      algorithms.stableSort(
        [
          ...GIS_TABS.map((tab) => {
            if (tab?.name !== selected && tab.active) {
              return { ...tab, active: false };
            } else if (tab.name === selected) {
              return { ...tab, active: true };
            } else return tab;
          }),
        ],
        algorithms.sort(SORT_DIRECTION.DESC, "order")
      )
    );
  }, [selected, authUser, setTabs]);

  useEffect(() => {
    if (data) {
      "regions" in data ? setRegions(data.regions) : setRegions([]);
      "districts" in data ? setDistricts(data.districts) : setDistricts([]);
    }
  }, [data]);

  return (
    <Fragment>
      <div className="relative min-w-full h-full overflow-x-auto shadow-none sm:rounded-lg">
        <ToolBar>
          <div className="flex flex-row pb-3 w-full">
            <div className="flex flex-row">
              {Boolean(tabs?.length > 0) ? (
                <Tabs>
                  {tabs.map((tab, index) => (
                    <Tab
                      key={`${tab?.label}-${index}`}
                      id={tab?.name}
                      label={tab?.label}
                      name={tab?.name}
                      selected={tab?.active}
                      onSelected={handleSelectedTab}
                    />
                  ))}
                </Tabs>
              ) : null}
            </div>
          </div>
        </ToolBar>
        <CMSMapContent
          loading={
            districtFeatureLoading ||
            districtShapeLoading ||
            regionShapeLoading ||
            loadinRegionsAndDistricts
          }
          activeTab={selected}
          handleGisSelectionChange={handleGisSelectionChange}
          handleSelectedDistrictForGis={handleSelctedDistrictFOrEnrgyResource}
          districts={districts}
          selectedDistrictForEnergyResource={selectedDistrictForEnergyResource}
          regions={regions}
          region={regionShape}
          district={districtShape}
          selectedErgyResource={selectedEnergyResource}
          districtFeatures={districtFeature}
        />
      </div>
    </Fragment>
  );
};

export default CMSGisListing;
