import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import ErrorBoundary from "./components/error.boundary";
import navRouter from "./routes";
import UserAuthProvider from "./common/providers/auth.provider";
import { RecoilRoot } from "recoil";
import App from "./App";
import ProgressLoader from "./components/common/progress";
// import "leaflet/dist/leaflet.css";

const AppWrapper = lazy(() => import("./components/app.wrapper"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <App>
      <ErrorBoundary>
        <Suspense fallback={<ProgressLoader loading={true} />}>
          <UserAuthProvider>
            <AppWrapper>
              <RouterProvider router={navRouter} />
            </AppWrapper>
          </UserAuthProvider>
        </Suspense>
      </ErrorBoundary>
    </App>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
