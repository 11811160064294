import { lazy } from "react";
import { Outlet } from "react-router-dom";
import UserResetPassword from "../components/users/forgot.passwod";

const PageNotFound = lazy(() => import("../components/page.notfound"));
const UserLoginForm = lazy(() => import("../components/users/user.login"));
const Home = lazy(() => import("../components/home"));
const PublicContentWrapper = lazy(() =>
  import("../components/public.content.wrapper")
);
const FAQs = lazy(() => import("../components/faqs"));
const Financing = lazy(() => import("../components/financing"));
const Library = lazy(() => import("../components/library"));
const Licensing = lazy(() => import("../components/licensing"));
const GIS = lazy(() => import("../components/gis"));
const News = lazy(() => import("../components/news"));
const Directory = lazy(() => import("../components/directory"));
const StakeholderDetails = lazy(() =>
  import("../components/directory/stakeholder.details")
);
const ArticleDetails = lazy(() => import("../components/news/article.details"));

export const publicRoutes = [
  {
    path: "*",
    element: <PageNotFound />,
    children: [],
  },
  {
    path: "/login",
    element: <UserLoginForm />,
    children: [],
  },
  {
    path: "/",
    element: (
      <PublicContentWrapper>
        <Outlet />
      </PublicContentWrapper>
    ),
    children: [
      {
        path: "",
        element: <Home />,
        children: [],
      },
      {
        path: "library",
        element: <Library />,
        children: [],
      },
      {
        path: "faqs",
        element: <FAQs />,
        children: [],
      },
      {
        path: "licensing",
        element: <Licensing />,
        children: [],
      },
      {
        path: "financing",
        element: <Financing />,
        children: [],
      },
      {
        path: "news",
        element: <News />,
        children: [],
      },
      {
        path: "news/:name",
        element: <ArticleDetails />,
        children: [],
      },
      {
        path: "gis",
        element: <GIS />,
        children: [],
      },
      {
        path: "directory",
        element: <Directory />,
        children: [],
      },
      {
        path: "directory/:name",
        element: <StakeholderDetails />,
        children: [],
      },
      {
        path: "contact",
        element: <>Contact Administrator Page</>,
        children: [],
      },
    ],
  },
  {
    path: "/reset-password/:token",
    element: <UserResetPassword />,
    children: [],
  },
];
