import React from "react";

export const Button = ({
  label,
  className,
  disabled = false,
  handleOnClick,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      onClick={handleOnClick}
      className={`flex flex-row px-3 py-1.5 max-sm:py-2 text-2xl max-sm:text-sm text-gray-700 ${className} font-semibold rounded-md shadow-xs hover:cursor-pointer sm:text-sm sm:leading-6`}
    >
      {children}
      <div>{label}</div>
    </button>
  );
};

const ButtonInputField = ({ type, value, disabled, ...props }) => {
  return (
    <input
      {...props}
      type={type}
      value={value}
      disabled={disabled}
      className={[
        "mr-2 px-3 py-1 max-sm:py-2 text-2xl max-sm:text-sm text-white font-semibold rounded-md shadow-sm ring-1 ring-inset bg-green-600 hover:bg-green-700 hover:cursor-pointer sm:text-sm sm:leading-6",
        disabled ? " bg-green-700 hover:cursor-not-allowed " : "",
      ].join(" ")}
    />
  );
};

export default ButtonInputField;
